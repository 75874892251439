import React, { useCallback, useEffect, useMemo } from 'react'
import { OfflineContext } from './OfflineContext'
import { OfflineAction, OfflineActionData, OfflineActionStatus, SynchronisedVaccinationData, SynchronisedVaccinationDataItem } from '../offlineModel'
import { OFFLINE_ACTION, OFFLINE_DATA } from '../offlineConstants'
import {
	deleteStorageOfflineActions, deleteStorageOfflineMode,
	getStorageOfflineActions,
	getStorageOfflineBl,
	getStorageOfflineData,
	getStorageOfflineGlobalContext,
	getStorageOfflineStock,
	setStorageOfflineActions,
	setStorageOfflineData,
	setStorageOfflineMode
} from '../offlineStorage'
import { TRANSFERT_TYPE, VALUE_LIST_SHORTCUT } from '../../../../utils/constants'
import { useIntl } from 'react-intl'
import { formatEntryAction, formatExitAction, formatScrappingAction, formatUpdateStockAction } from '../offlineUtils'
import moment from 'moment'
import { VaccinationStockModel } from '../../../vaccination/services/vaccinationModels'
import { getBlList } from '../../..//vaccination/services/vaccinationApi'
import { getVaccinationStockList } from '../../../common/stock/vaccinationStockApi'

type OfflineModeProviderProps = {
	children: React.FC
}

const responseOffline = { offline: true }
let backgroundSyncTimer: NodeJS.Timer | undefined

const OfflineProvider = ({ children }: OfflineModeProviderProps) => {
	const intl = useIntl()
	const [offlineMode, setOfflineMode] = React.useState<boolean>(false)
	const [backgroundSync, setBackgroundSync] = React.useState<boolean>(false)
	const [lastSynchronisation, setLastSynchronisation] = React.useState<string | undefined>(undefined)
	const [offlineData, setOfflineData] = React.useState<SynchronisedVaccinationData>(getStorageOfflineData())
	const [offlineActionList, setOfflineActionlist] = React.useState<OfflineAction<OfflineActionData>[]>(getStorageOfflineActions())

	// Storing offline mode
	const handleOfflineMode = useCallback((isOffline: boolean) => {
		setOfflineMode(isOffline)
		setStorageOfflineMode(isOffline)

		if (!isOffline) {
			deleteStorageOfflineMode()
		}
	}, [setOfflineMode])

	// Storing data after synchronization
	const storeOfflineData = useCallback(<T extends SynchronisedVaccinationDataItem>(type: OFFLINE_DATA, data: T) => {
		setOfflineData(state => {
			const newData = {
				...(state || {}),
				[type]: data
			}
			setStorageOfflineData(newData)
			return newData
		})
		setLastSynchronisation(moment().toISOString())
	}, [])

	const loadOfflineData = useCallback(() => {
		const promises = [getBlList(), getVaccinationStockList()]
		Promise.all(promises)
			.then(res => {
				storeOfflineData(OFFLINE_DATA.BL, res[0])
				storeOfflineData(OFFLINE_DATA.STOCK, res[1])
			})
	}, [storeOfflineData])

	// Automatic synchronization in background on online mode
	useEffect(() => {
		if (backgroundSyncTimer) {
			clearInterval(backgroundSyncTimer)
		}
		if (backgroundSync && !offlineMode) {
			loadOfflineData()
			backgroundSyncTimer = setInterval(() => {
				loadOfflineData()
			}, 180000)
		}
	}, [backgroundSync, offlineMode, loadOfflineData])

	// Used to lock user in disconnected mode
	const hasOfflineActionsToSend = useMemo(() => offlineActionList.some(a => a.status === 'WAITING'), [offlineActionList])
	// Used to show retry error button
	const hasErrorsToSend = useMemo(() => offlineActionList.some(a => a.status === 'ERROR'), [offlineActionList])

	// Update actions after trying to send to server
	const updateActionStatus = useCallback((uid: string, status: OfflineActionStatus) => {
		setOfflineActionlist(state => {
			const newState = [...state]
			const actionIndex = state.findIndex(s => s.uid === uid)
			newState.splice(actionIndex, 1, {
				...(state[actionIndex] || {}),
				status: status
			})
			setStorageOfflineActions(newState)
			return newState
		})
	}, [])

	// Clear actions
	const clearOfflineActionList = useCallback(() => {
		setOfflineActionlist([])
		deleteStorageOfflineActions()
	}, [])

	// Storing actions after submission
	const storeAction = useCallback((action: OfflineAction<OfflineActionData>) => {
		setOfflineActionlist(state => {
			const newActions = [...state, action]
			setStorageOfflineActions(newActions)
			return newActions
		})
	}, [])

	// Handle all offline actions
	// Online -> Api call
	// Offline -> Offline logic with state and storage
	const handleOfflineAction = useCallback((type: OFFLINE_ACTION, promise: (values?: any) => Promise<any>, values?: any): Promise<any> => {
		if (!offlineMode) {
			return promise(values)
		}

		switch (type) {
			case OFFLINE_ACTION.GET_BL_LIST:
				return Promise.resolve(getStorageOfflineBl())
			case OFFLINE_ACTION.GET_BL_BY_PRODUCT:
				return Promise.resolve(getStorageOfflineStock().filter(bl => bl.ligneBl?.stock?.produit.id === values && bl.quantiteStock > 0))
			case OFFLINE_ACTION.GET_STOCK_PAGE: {
				const stockList = getStorageOfflineStock()
				const rangeStart = values.page * 5
				const rangeEnd = (values.page + 1) * 5

				// We can filter by product and batch number
				const filteredStock = stockList
					.filter(s =>
						((!!values.idProduit && s.ligneBl.produit.id === values.idProduit) || !values.idProduit) &&
						((!!values.lot && s.ligneBl.stock?.lot.includes(values.lot)) || !values.lot)
					)

				return Promise.resolve({
					content: filteredStock.slice(rangeStart, rangeEnd),
					totalElements: filteredStock.length
				})
			}
			case OFFLINE_ACTION.POST_ENTRY_STOCK: {
				const selectedBl = getStorageOfflineBl().find(bl => bl.numeroBl === values.numeroBl)

				if (!selectedBl) {
					return Promise.reject()
				}
				storeAction(formatEntryAction(values))

				const { user: { selectedCenter } } = getStorageOfflineGlobalContext()


				// Update bl list
				// Foreach bl we store stock and drop available bl
				const newStocks: VaccinationStockModel[] = selectedBl.lignes
					.filter(ligneBl => ligneBl.stock)
					.map(ligneBl => {
						const stability = ligneBl.stock?.transfertType === TRANSFERT_TYPE.MOINS_20 ? ligneBl.produit.stabilite28TransfertMoins20 : ligneBl.produit.stabilite28
						return {
							id: `temp-${ligneBl.id}`,
							quantiteStock: ligneBl.quantite,
							limiteUtilisation: moment(ligneBl.dateSortie).add(stability, 'hours').toISOString(),
							commentaire: values.commentaire,
							conforme: values.conforme,
							ligneBl: ligneBl,
							centreVacc: selectedCenter,
							history: ligneBl.history
						}
					})

				setOfflineData(state => {
					const newData = {
						...(state || {}),
						[OFFLINE_DATA.BL]: ((state || {})[OFFLINE_DATA.BL] || [])
							.filter(bl => bl.numeroBl !== values.numeroBl),
						[OFFLINE_DATA.STOCK]: [...newStocks, ...state[OFFLINE_DATA.STOCK]]
					}

					setStorageOfflineData(newData)
					return newData
				})
				return Promise.resolve(responseOffline)
			}
			case OFFLINE_ACTION.POST_EXIT_FRIDGE:
			case OFFLINE_ACTION.POST_SCRAPPING: {
				const selectedStock = getStorageOfflineStock().find(s => s.ligneBl.id === values.idLigneBl)
				if (!selectedStock) {
					return Promise.reject({
						bodyError: {
							fieldErrors: [{
								code: 'global.error.batchNotFound',
								defaultMessage: 'global.error.batchNotFound',
								field: 'idLigneBl'
							}]
						}
					})
				}

				// Validation du stock
				if (selectedStock.quantiteStock - values.quantite < 0) {
					return Promise.reject({
						bodyError: {
							fieldErrors: [{
								code: 'global.error.quantityMax',
								defaultMessage: 'global.error.quantityMax',
								field: 'quantite'
							}]
						}
					})
				}

				if (type === OFFLINE_ACTION.POST_EXIT_FRIDGE) {
					const { user: { selectedCenter: { centreEnfantsByType: { vaccinations } } } } = getStorageOfflineGlobalContext()
					storeAction(formatExitAction(selectedStock, vaccinations, values, promise))
				} else {
					const { valueList } = getStorageOfflineGlobalContext()
					const scrappingCauseOptions = valueList[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE] || {}
					storeAction(formatScrappingAction(selectedStock, scrappingCauseOptions, values, intl.formatMessage({ id: 'common.scrapping.scrappingOtherOption' })))
				}

				// Update stock list
				setOfflineData(state => {
					const newStock = [...((state || {})[OFFLINE_DATA.STOCK] || [])]
					const stockIndex = newStock.findIndex(s => s.ligneBl.id === values.idLigneBl)
					newStock[stockIndex] = {
						...selectedStock,
						quantiteStock: selectedStock.quantiteStock - values.quantite
					}
					const newData = {
						...(state || {}),
						[OFFLINE_DATA.STOCK]: newStock
					}
					setStorageOfflineData(newData)
					return newData
				})
				return Promise.resolve(responseOffline)
			}
			case OFFLINE_ACTION.UPDATE_STOCK:
				const selectedStock = getStorageOfflineStock().find(s => s.id === values.idStock)
				if (!selectedStock) {
					return Promise.reject({
						bodyError: {
							fieldErrors: [{
								code: 'global.error.batchNotFound',
								defaultMessage: 'global.error.batchNotFound',
								field: 'idLigneBl'
							}]
						}
					})
				}

				// Validation du stock
				if (selectedStock.quantiteStock + parseInt(values.quantite) < 0) {
					return Promise.reject({
						bodyError: {
							fieldErrors: [{
								code: 'global.error.quantityMax',
								defaultMessage: 'global.error.quantityMax',
								field: 'quantite'
							}]
						}
					})
				}

				// Store action
				storeAction(formatUpdateStockAction(selectedStock, values))

				// Update stock list
				setOfflineData(state => {
					const newStock = [...((state || {})[OFFLINE_DATA.STOCK] || [])]
					const stockIndex = newStock.findIndex(s => s.id === values.idStock)
					newStock[stockIndex] = {
						...selectedStock,
						quantiteStock: selectedStock.quantiteStock + parseInt(values.quantite)
					}
					const newData = {
						...(state || {}),
						[OFFLINE_DATA.STOCK]: newStock
					}
					setStorageOfflineData(newData)

					return newData
				})
				return Promise.resolve(responseOffline)
			default:
				return Promise.resolve([])
		}
	}, [offlineMode, storeAction, intl])

	// Context values
	const contextValues = useMemo(() => ({
		offlineMode,
		handleOfflineMode,
		backgroundSync,
		setBackgroundSync,
		lastSynchronisation,
		offlineData,
		loadOfflineData,
		storeAppContext: storeOfflineData,
		hasOfflineActionsToSend,
		hasErrorsToSend,
		offlineActionList,
		handleOfflineAction,
		updateActionStatus,
		clearOfflineActionList
	}), [
		offlineMode,
		handleOfflineMode,
		backgroundSync,
		setBackgroundSync,
		lastSynchronisation,
		offlineData,
		loadOfflineData,
		storeOfflineData,
		hasOfflineActionsToSend,
		hasErrorsToSend,
		offlineActionList,
		handleOfflineAction,
		updateActionStatus,
		clearOfflineActionList
	])

	return <OfflineContext.Provider value={contextValues}>
		{children}
	</OfflineContext.Provider>
}

export default OfflineProvider
