import { IsotopeContext, loadLangues, logOut as logOutAction, ProfileMenu, refresh as refreshApi, refreshToken as refreshTokenAction, storeLocalUser as storeLocalUserApi, ToolbarContext } from 'isotope-client'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PhidemDataProvider from '../modules/common/phidemData/PhidemDataProvider'
import BlocHeader from '../components/layout/BlocHeader'
import { LOCAL_STORAGE_ITEM } from '../utils/constants'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import LanguageSelector from '../config/messages/LanguageSelector'
import OfflineProvider from '../modules/common/offline/context/OfflineProvider'
import AppRoutes from './AppRoutes'

/**
 * Interval de rafraîchissement du token, en secondes.
 */
const REFRESH_INTERVAL = 1700

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOnline: navigator.onLine
		}

		if (navigator.onLine) {
			this.refresh()
		}
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, REFRESH_INTERVAL * 1000)

		window.addEventListener('online', () => this.setState({ isOnline: true }))
		window.addEventListener('offline', () => this.setState({ isOnline: false }))
	}

	refresh() {
		const { user, refreshAction, loadLanguesAction } = this.props
		if (this.state.isOnline) {
			if (user && user.token) {
				const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
				refreshApi(token).then((json) => {
					if (json) {
						refreshAction(json.token)
						storeLocalUserApi(json)
					}
				})
			}
			loadLanguesAction()
		}
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
		window.removeEventListener('online', () => this.setState({ isOnline: true }))
		window.removeEventListener('offline', () => this.setState({ isOnline: false }))
	}

	render() {
		const { location, user, session } = this.props

		if (user.authenticated) {
			return (
				<OfflineProvider>
					<IsotopeContext.Provider value={{ appName: '', logo: BlocHeader }}>
						<ToolbarContext.Provider value={{
							children: [
								<Link key="notice" to="/notice"><HelpOutlineIcon style={{ color: '#FFFFFF', marginRight: 10 }} /></Link>,
								<LanguageSelector key="language" />,
								<ProfileMenu key="profile" />
							]
						}}>
							<PhidemDataProvider>
								<AppRoutes location={location} session={session} />
							</PhidemDataProvider>
						</ToolbarContext.Provider>
					</IsotopeContext.Provider>
				</OfflineProvider>
			)
		}
		return (
			<Redirect to="/login" />
		)
	}
}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object,
	session: PropTypes.object,
	loadLanguesAction: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: state.user,
	session: state.session
})

const actions = {
	refreshAction: refreshTokenAction,
	logout: logOutAction,
	loadLanguesAction: loadLangues
}

export default connect(mapStateToProps, actions)(App)
