import React, { useContext } from 'react'
import { PhidemDataContext } from './PhidemDataContext'
import { ApplicationConfiguration, AuthorityModel, CentreInfoModel, CentreModel, initApplication, initCentre, initDispatchData, PhidemReferentialDataModel, REFERENTIEL, ResponseValueListModel, ValueListDicModel, ValueListModel } from './phidemDataModel'
import { getAllCarriers, getAllContainers, getAllProducts, getAllTrackers, getAvailableTemplates, getMyCenter, getMyCenterList, getValueListByShortcut } from './phidemDataApi'
import { connect } from 'react-redux'
import { CENTER_TYPE, LANGUAGES_CODE, PRODUCT_TYPES, USER_ROLE, VALUE_LIST_SHORTCUT } from '../../../utils/constants'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import Loader from '../../../components/layout/Loader'
import { SessionReducer } from '../session/sessionReducer'
import { getStorageCenter, switchCenter } from '../session/sessionActions'
import { TemplateRowModel } from '../../admin/template/services/templateModels'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../admin/container/services/containerModel'
import { TrackerModel } from '../../admin/tracker/services/trackerModel'
import { RequestReplenishmentListModel, RequestReplenishmentPointListModel } from '../../dispatch/services/dispatchModels'
import { getLanguageIdByCode } from '../../../utils/formUtils'
import { OfflineContext } from '../offline/context/OfflineContext'
import { getStorageOfflineMode } from '../offline/offlineStorage'

interface AdminRights {
	isAdmin: boolean,
	isAdminDispatch: boolean
}

interface AcceptingProps {
	children: React.FC
	appConfiguration: ApplicationConfiguration
	authorities: AuthorityModel[]
	session: SessionReducer
	selectedLang: string
	switchCenter: (center?: any) => void
}

type PhidemDataProps = AcceptingProps

const PhidemDataProvider: React.FC<PhidemDataProps> = (
	{
		children,
		appConfiguration,
		authorities,
		session,
		selectedLang,
		switchCenter
	}
) => {
	const { handleOfflineMode, setBackgroundSync } = useContext(OfflineContext)
	const [vaccins, setVaccins] = React.useState<VaccinModel[]>([])
	const [products, setProducts] = React.useState<ProductModel[]>([])
	const [templates, setTemplates] = React.useState<TemplateRowModel[]>([])
	const [userCentre, setUserCentre] = React.useState<CentreModel>(initCentre)
	const [userCentreList, setUserCentreList] = React.useState<CentreInfoModel[]>([])
	const [referentialData, setReferentialData] = React.useState<PhidemReferentialDataModel>(initDispatchData)
	const [valueList, setValueList] = React.useState<ValueListDicModel>({})
	const [isLoading, setIsLoading] = React.useState<boolean>(true)
	const [adminRight, setAdminRight] = React.useState<AdminRights>({ isAdmin: false, isAdminDispatch: false })
	const [replenishmentFilters, setReplenishmentFilters] = React.useState<RequestReplenishmentListModel>(initApplication.replenishment.filters)
	const [replenishmentPointFilters, setReplenishmentPointFilters] = React.useState<RequestReplenishmentPointListModel>(initApplication.replenishmentPoint.filters)

	// Au montage, on récupère les centres de l'utilisateur
	React.useEffect(() => {
		getMyCenterList().then((result: CentreInfoModel[]) => {
			setUserCentreList(result)

			// init avec le storage
			const centerId = getStorageCenter()
			let centerSelected = undefined

			// On vérifie que l'on a des résultats
			if (result.length > 0) {
				if (!!centerId && centerId.length > 0) {
					const centerFound = result.find((center: CentreInfoModel) => center.id === centerId)
					if (centerFound) {
						centerSelected = {
							type: centerFound.type,
							id: centerFound.id
						}
					}
				} else {
					centerSelected = {
						type: result[0].type,
						id: result[0].id
					}
				}
			}

			// Start offline mode
			if (centerSelected && centerSelected.type === CENTER_TYPE.VACCINATION && getStorageOfflineMode() === 'true') {
				handleOfflineMode(true)
			}

			switchCenter(centerSelected)
		})
	}, [switchCenter, handleOfflineMode])

	const getChildrenCenter = React.useCallback((center: CentreModel): CentreModel[] => {
		if (center.centreEnfants && center.centreEnfants.length > 0) {
			return [center, ...center.centreEnfants.reduce((acc: CentreModel[], curr) =>
					[...acc, ...getChildrenCenter(curr)]
				, [])]
		}
		return [center]
	}, [])

	// Update children centers
	// Vaccination & Point => centreVaccEnfants
	// Référent & Dispatch => centreRefDispEnfants
	const setFlattenUserCentres = React.useCallback((centre: CentreModel) => {
		const children = getChildrenCenter(centre)
		centre.centreEnfantsByType = {
			refDispatchs: children.filter(c => c.id !== centre.id && (c.type === CENTER_TYPE.REFERENT || c.type === CENTER_TYPE.DISPATCH)),
			vaccinations: children.filter(c => c.id !== centre.id && (c.type === CENTER_TYPE.VACCINATION || c.type === CENTER_TYPE.VACCINATION_POINT))
		}

		setUserCentre(centre)
		// Offline center = start background sync
		setBackgroundSync(centre.modeOffline)
	}, [getChildrenCenter, setBackgroundSync])

	// On récupère les données selon le type de centre
	React.useEffect(() => {
		if (!session.isLoading) {
			if (session.centerType === CENTER_TYPE.REFERENT || session.centerType === CENTER_TYPE.DISPATCH) {
				const promises = [
					getMyCenter(),
					getAllProducts(),
					getAllCarriers(),
					getAllContainers(),
					getAllTrackers(),
					getAvailableTemplates()
				]

				Promise.all(promises)
					.then((response: any) => {
						setFlattenUserCentres(response[0])
						const resProducts: ProductModel[] = response[1]
						setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
						setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
						setReferentialData({
							carriers: response[2],
							containers: response[3],
							trackers: response[4]
						})

						setAdminRight({
							isAdmin: false,
							isAdminDispatch: session.centerType !== CENTER_TYPE.REFERENT && authorities.findIndex((auth: AuthorityModel) => auth.authority === USER_ROLE.ROLE_ADMIN_DISPATCH) !== -1
						})
						setTemplates(response[5])
					})
					.finally(() => {
						setIsLoading(false)
					})
			} else if (session.centerType === CENTER_TYPE.VACCINATION || session.centerType === CENTER_TYPE.VACCINATION_POINT) {
				const promises = [
					getMyCenter(),
					getAllProducts()
				]

				setReplenishmentFilters(state => ({
					...state,
					idCentre: session.centerId
				}))

				Promise.all(promises)
					.then((response: any) => {
						setFlattenUserCentres(response[0])

						const resProducts: ProductModel[] = response[1]
						setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
						setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
					})
					.finally(() => {
						setIsLoading(false)
					})
			} else {
				const promises = [
					getAllProducts(),
					getAvailableTemplates(),
					getAllCarriers()
				]

				Promise.all(promises)
					.then((response: any) => {
						const resProducts: ProductModel[] = response[0]
						setVaccins(resProducts.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
						setProducts(resProducts.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
						setTemplates(response[1])
						setAdminRight({
							isAdmin: true,
							isAdminDispatch: false
						})
						setReferentialData({
							carriers: response[2],
							containers: [],
							trackers: []
						})

					})
					.finally(() => {
						setIsLoading(false)
					})
			}
		}
	}, [session, authorities, setFlattenUserCentres])

	// On récupère les listes de valeurs internationalisées
	const formatValueList = (list: ResponseValueListModel[]) => {
		return list
			.reduce((acc: ValueListModel, item: ResponseValueListModel) => ({
				...acc,
				[item.code]: item.label
			}), {})
	}

	React.useEffect(() => {
		const promises = [
			getValueListByShortcut(VALUE_LIST_SHORTCUT.PRODUCT_TYPE, getLanguageIdByCode(selectedLang)),
			getValueListByShortcut(VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE, getLanguageIdByCode(selectedLang))
		]

			Promise.all(promises)
				.then((response: ResponseValueListModel[][]) => setValueList({
				[VALUE_LIST_SHORTCUT.PRODUCT_TYPE]: formatValueList(response[0]),
				[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE]: formatValueList(response[1])
			}))
	}, [selectedLang])

	const reloadReferentiel = (key: string) => {
		switch (key) {
			case REFERENTIEL.CENTER:
				if (session.centerType === CENTER_TYPE.DISPATCH || session.centerType === CENTER_TYPE.VACCINATION) {
					getMyCenter().then(setFlattenUserCentres)
				}
				break
			case REFERENTIEL.CARRIER:
				getAllCarriers()
					.then((res: CarrierModel[]) => {
						setReferentialData((state) => ({
							...state,
							carriers: res
						}))
					})
				break
			case REFERENTIEL.CONTAINER:
				getAllContainers()
					.then((res: ContainerModel[]) => {
						setReferentialData((state) => ({
							...state,
							containers: res
						}))
					})
				break
			case REFERENTIEL.PRODUCT:
				getAllProducts()
					.then((response: ProductModel[]) => {
						setProducts(response.filter((product: ProductModel) => product.type !== PRODUCT_TYPES.VACCIN))
						setVaccins(response.filter((product: ProductModel) => product.type === PRODUCT_TYPES.VACCIN) as VaccinModel[])
					})
				break
			case REFERENTIEL.TEMPLATE:
				getAvailableTemplates().then(setTemplates)
				break
			case REFERENTIEL.TRACKER:
				getAllTrackers()
					.then((res: TrackerModel[]) => {
						setReferentialData((state) => ({
							...state,
							trackers: res
						}))
					})
				break
			default:
				break
		}
	}

	return <PhidemDataContext.Provider value={{
		vaccins,
		products,
		templates,
		referentialData,
		user: {
			selectedCenter: userCentre,
			centerList: userCentreList,
			isAdmin: adminRight.isAdmin,
			isAdminDispatch: adminRight.isAdminDispatch,
			selectedLang,
			isDateFormatEn: selectedLang === LANGUAGES_CODE.EN
		},
		application: {
			replenishment: {
				filters: replenishmentFilters,
				setFilters: setReplenishmentFilters
			},
			replenishmentPoint: {
				filters: replenishmentPointFilters,
				setFilters: setReplenishmentPointFilters
			},
			configuration: appConfiguration
		},
		valueList,
		reloadReferentiel
	}}>
		{isLoading
			?
			<Loader />
			:
			children
		}
	</PhidemDataContext.Provider>
}

const mapStateToProps = (state: any) => ({
	appConfiguration: state.appConfiguration,
	session: state.session,
	authorities: (state.user && state.user.authorities) || [],
	selectedLang: state.intl.locale
})

const actions = {
	switchCenter
}

export default connect(mapStateToProps, actions)(PhidemDataProvider)
