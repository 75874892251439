import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { MuiThemeProvider } from '@material-ui/core'
import { ConnectedRouter, connectRouter, replace, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { configureAuthApi, configureFetch, configureStorageMode, fetchUser as fetchUserApi, getLocalUser, getUser, logOut } from 'isotope-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { intlReducer, Provider } from 'react-intl-redux'
import { reducer as formReducer } from 'redux-form'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import messagesFr from './config/messages/fr'
import messagesEn from './config/messages/en'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'
import theme from './config/theme'
import { fetchFactory, fetchUser, login, postMultipart, refresh, saveStore } from './utils/fetch'
import { getStorageCenter } from './modules/common/session/sessionActions'
import { LANGUAGES_CODE } from './utils/constants'
import { getApplicationConfiguration } from './modules/common/phidemData/phidemDataApi'
import { ApplicationConfiguration } from './modules/common/phidemData/phidemDataModel'
import ServiceWorkerWrapper from './modules/serviceWorker/ServiceWorkerWrapper'

// Gestion IE
if (!Intl.PluralRules) {
	import ('@formatjs/intl-getcanonicallocales/polyfill')
	import ('@formatjs/intl-locale/polyfill')
	import('@formatjs/intl-pluralrules/polyfill')
	// @ts-ignore
	import('@formatjs/intl-pluralrules/locale-data/fr')
}
if (!Intl.RelativeTimeFormat) {
	import('@formatjs/intl-relativetimeformat/polyfill')
	// @ts-ignore
	import('@formatjs/intl-relativetimeformat/locale-data/fr')
}


// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user?: User, configuration?: ApplicationConfiguration) => {

	// Initialisation de la partie i18n (react-intl)
	const formats = {
		date: {
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}

	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// En mode dév, on utilise le devtools
	// @ts-ignore
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		// @ts-ignore
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	const defaultLang = configuration?.defaultLanguage || LANGUAGES_CODE.EN

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer,
			appConfiguration: () => configuration || {}
		}), {
			user,
			intl: {
				locale: defaultLang,
				messages: {
					...(defaultLang === LANGUAGES_CODE.FR ? messagesFr : messagesEn)
				},
				formats: {
					...formats
				},
				defaultLocale: defaultLang,
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	saveStore(store)

	// On gère la déconnexion multi-onglets pour le local storage
	window.addEventListener('storage', () => {
		// Au changement de centre dans le storage par un autre onglet, on déconnecte
		const storageCenter = getStorageCenter()
		const state: any = store.getState()
		if (storageCenter !== state.session.centerId) {
			store.dispatch(logOut())
		}

		const token = getLocalUser()
		if (token) {
			fetchUserApi(token)
				.then((user: any) => {
					if (user) {
						user.token = token
						user.authenticated = true
					}
					store.dispatch(getUser(user))
					store.dispatch(replace('/'))
				})
				.catch(() => {
					store.dispatch(logOut())
				})
		} else {
			store.dispatch(logOut())
		}
	})

	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MuiThemeProvider theme={theme}>
					<ServiceWorkerWrapper>
						<Root />
					</ServiceWorkerWrapper>
				</MuiThemeProvider>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	)
}

configureStorageMode()
configureFetch({ fetchFactory, postMultipart })
configureAuthApi({ login, fetchUser, refresh })

const startApp = (configuration?: ApplicationConfiguration) => {
	const token = getLocalUser()
	if (token) {
		fetchUserApi(token).then((user: User) => {
			if (user) {
				user.token = token
				user.authenticated = true
			}
			reactInit(user, configuration)
		}).catch(() => reactInit(undefined, configuration))
	} else {
		reactInit(undefined, configuration)
	}
}

getApplicationConfiguration()
	.then((configuration: ApplicationConfiguration) => startApp(configuration))
	.catch(() => startApp())


// Webpack Hot Module Replacement API
// @ts-ignore
if (module.hot) {
	// @ts-ignore
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}
