import { LOCAL_STORAGE_ITEM } from '../../../utils/constants'
import { OfflineAction, OfflineActionData, SynchronisedVaccinationData } from './offlineModel'
import { OFFLINE_DATA } from './offlineConstants'
import { initPhidemContextData } from '../phidemData/PhidemDataContext'
import { VaccinationStockModel } from '../../vaccination/services/vaccinationModels'
import { DispatchBlModel } from '../../dispatch/services/dispatchModels'
import { PhidemDataModel } from '../../common/phidemData/phidemDataModel'
import { initOfflineData } from './context/OfflineContext'

// DATA
export const getStorageOfflineData = () => {
	const data = localStorage.getItem(LOCAL_STORAGE_ITEM.OFFLINE_DATA)
	return data ? JSON.parse(data) : initOfflineData
}
export const getStorageOfflineGlobalContext = (): PhidemDataModel => getStorageOfflineData()[OFFLINE_DATA.GLOBAL] || initPhidemContextData
export const getStorageOfflineBl = (): DispatchBlModel[] => getStorageOfflineData()[OFFLINE_DATA.BL] || []
export const getStorageOfflineStock = (): VaccinationStockModel[] => getStorageOfflineData()[OFFLINE_DATA.STOCK] || []

export const setStorageOfflineData = (data: SynchronisedVaccinationData) => localStorage.setItem(LOCAL_STORAGE_ITEM.OFFLINE_DATA, JSON.stringify(data))
export const deleteStorageOfflineData = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.OFFLINE_DATA)


// ACTIONS
export const getStorageOfflineActions = (): OfflineAction<OfflineActionData>[] => JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM.OFFLINE_ACTIONS) || '[]')
export const setStorageOfflineActions = (data: OfflineAction<OfflineActionData>[]) => localStorage.setItem(LOCAL_STORAGE_ITEM.OFFLINE_ACTIONS, JSON.stringify(data))
export const deleteStorageOfflineActions = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.OFFLINE_ACTIONS)

// Mode
export const getStorageOfflineMode = () => localStorage.getItem(LOCAL_STORAGE_ITEM.OFFLINE_MODE)
export const setStorageOfflineMode = (offlineMode: boolean) => localStorage.setItem(LOCAL_STORAGE_ITEM.OFFLINE_MODE, `${offlineMode}`)
export const deleteStorageOfflineMode = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.OFFLINE_MODE)
