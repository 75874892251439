import { flatten } from 'flat'
import { ModelTranslations } from './messagesModel'

const fr = {
    accueil: 'Accueil',
    admin: 'Administrateur',
    dispatch_center: 'Centre de dispatch',
    vaccination_center: 'Centre de vaccination',
    vaccination_point: 'Point de vaccination',
    admin_dispatch: 'Administrateur de centres de dispatch',
    referent: 'Centre référent',
    menuHeader: {
        offlineMode: 'Mode déconnecté',
        onlineMode: 'Mode connecté',
        offlineDialog: {
            offlineTitle: 'Mode déconnecté',
            onlineTitle: 'Mode connecté',
            offlineDesc: 'Êtes-vous sûr de vouloir passer en mode déconnecté ?',
            onlineDesc: 'Êtes-vous sûr de vouloir passer en mode connecté ?',
            disabledDesc: 'Vous ne pouvez pas quitter le mode déconnecté tant que des actions sont en attente d\'envoi.'
        }
    },
    menu: {
        accueilMenu: 'Accueil',
        noticeMenu: 'Mentions légales',
        referentCenter: {
            affiliatedStock: 'Mes centres associés',
            home: {
                title: 'Centre référent {centerName}',
                tiles: {
                    withStock: {
                        enterStock: {
                            title: 'Entrée en stock',
                            body: 'Saisissez les informations sur les produits que vous réceptionnez.',
                            link: 'Faire une entrée en stock'
                        },
                        exitStock: {
                            title: 'Préparation / Expédition',
                            body: 'Gérez vos livraisons vers les centres.',
                            link: 'Faire une sortie de stock'
                        },
                        scrapping: {
                            title: 'Mise en déchet',
                            body: 'Déclarez les sorties de stock pour flacons cassés, inutilisables, périmés, ...',
                            link: 'Effectuer une mise en déchet'
                        },
                        replenishment: {
                            title: 'Liste des demandes',
                            body: 'Consulter et traiter les demandes de réapprovisionnement provenant des centres.',
                            link: 'Voir les demandes'
                        },
                        dashboard: {
                            title: 'Tableau de bord',
                            body: 'Consultez en temps réel la synthèse des stocks, des mouvements, ...',
                            link: 'Voir le tableau de bord'
                        },
                        movementHistory: {
                            title: 'Historique des mouvements',
                            body: 'Consultez les mouvements de stock par produit.',
                            link: 'Voir les mouvements de stock'
                        }
                    },
                    withoutStock: {
                        affiliatedCenters: {
                            title: 'Mes centres associés',
                            body: 'Consultez les stocks de mes centres associés.',
                            link: 'Voir les stocks'
                        },
                        procurement: {
                            title: 'Allocation',
                            body: 'Consultez les allocations de mes centres associés.',
                            link: 'Voir les allocations'
                        },
                        useDeclaration: {
                            title: 'Suivi des administrations',
                            body: 'Consultez les administrations de mes centres associés.',
                            link: 'Voir les administrations'
                        }
                    }
                }
            }
        },
        dispatchCenter: {
            stockReturn: 'Retour de produit',
            affiliatedStock: 'Mes centres de vaccination',
            productReturn: 'Retour produit',
            home: {
                title: 'Centre de dispatch {centerName}',
                tiles: {
                    enterStock: {
                        title: 'Entrée en stock',
                        body: 'Saisissez les informations sur les produits que vous réceptionnez.',
                        link: 'Faire une entrée en stock'
                    },
                    exitStock: {
                        title: 'Préparation / Expédition',
                        body: 'Gérez vos livraisons vers les centres de vaccinations.',
                        link: 'Faire une sortie de stock'
                    },
                    scrapping: {
                        title: 'Mise en déchet',
                        body: 'Déclarez les sorties de stock pour flacons cassés, inutilisables, périmés, ...',
                        link: 'Effectuer une mise en déchet'
                    },
                    replenishment: {
                        title: 'Liste des demandes',
                        body: 'Consulter et traiter les demandes de réapprovisionnement provenant des centres.',
                        link: 'Voir les demandes'
                    },
                    dashboard: {
                        title: 'Tableau de bord',
                        body: 'Consultez en temps réel la synthèse des stocks, des mouvements, ...',
                        link: 'Voir le tableau de bord'
                    },
                    movementHistory: {
                        title: 'Historique des mouvements',
                        body: 'Consultez les mouvements de stock par produit.',
                        link: 'Voir les mouvements de stock'
                    }
                }
            }
        },
        vaccinationCenter: {
            offlineHome: 'Accueil mode déconnecté',
            offlineActions: 'Actions déconnectées',
            exit: 'Sortie de frigo',
            reapprovisionnement: 'Réapprovisionnement',
            replenishmentList: 'Historique des réapprovisionnements',
            home: {
                title: 'Centre de vaccination {centerName}',
                tiles: {
                    enterStock: {
                        title: 'Entrée en stock',
                        body: 'Saisissez les informations sur les produits que vous réceptionnez.',
                        link: 'Faire une entrée en stock'
                    },
                    exitFridge: {
                        title: 'Sortie de frigo',
                        body: 'Gérez l’utilisation des doses sortie de la chaine du froid.',
                        link: 'Faire une sortie de frigo'
                    },
                    declarationOfUse: {
                        title: 'Déclaration d\'administration',
                        body: 'Déclarez le nombre d’injections réalisée à des fins statistiques.',
                        link: 'Faire une déclaration'
                    },
                    replenishment: {
                        title: 'Faire une demande',
                        body: 'Envoyez vos demandes de réapprovisionnement vers votre centre de dispatch.',
                        link: 'Faire une demande'
                    },
                    dashboard: {
                        title: 'Tableau de bord',
                        body: 'Consultez en temps réel la synthèse des stocks, des mouvements, ...',
                        link: 'Voir le tableau de bord'
                    },
                    movementHistory: {
                        title: 'Historique des mouvements',
                        body: 'Consultez les mouvements de stock par produit.',
                        link: 'Voir les mouvements de stock'
                    }
                }
            }
        },
        vaccinationPoint: {
            home: {
                title: 'Point de vaccination {centerName}',
                tiles: {
                    replenishment: {
                        title: 'Faire une demande',
                        body: 'Envoyez vos demandes de réapprovisionnement vers votre centre de vaccination',
                        linki: 'Faire une demande'
                    },
                    replenishmentList: {
                        title: 'Historique des demandes',
                        body: 'Consulter vos demandes de réapprovisionnement',
                        link: 'Voir mes demandes'
                    }
                }
            },
            replenishment: 'Demande de réapprovisionnement',
            replenishmentList: 'Historique des demandes'
        },
        admin: {
            users: 'Utilisateurs',
            centres: 'Centres',
            produits: 'Produits',
            trackers: 'Trackers',
            contenants: 'Contenants',
            transporteurs: 'Transporteurs',
            stocks: 'Liste des stocks',
            replenishmentList: 'Demandes des centres',
            vaccinPointReplenishmentList: 'Demandes des points',
            template: 'Liste des templates',
            valueList: 'Liste de valeurs',
            mail: 'Envoi de mails',
            home: {
                title: 'Administration Promedeo',
                tiles: {
                    users: {
                        title: 'Utilisateurs',
                        body: 'Saisissez les utilisateurs, leurs droits, profils, ...',
                        link: 'Gérer les utilisateurs'
                    },
                    centers: {
                        title: 'Centres',
                        body: 'Gérez les centres de dispatch, centre de vaccinations, ...',
                        link: 'Gérer les centres'
                    },
                    products: {
                        title: 'Produits',
                        body: 'Gérez les fiches produits disponibles dans l’application.',
                        link: 'Gérer les produits'
                    },
                    stocks: {
                        title: 'Stocks',
                        body: 'Consultez les stocks dans les centres, effectuer des corrections.',
                        link: 'Voir les stocks'
                    },
                    replenishments: {
                        title: 'Demandes',
                        body: 'Consultez les demandes de réapprovisionnement des centres.',
                        link: 'Voir les demandes'
                    },
                }
            }
        },
        section: {
            referentCenter: 'Centre référent',
            dispatchCenter: 'Centre de dispatch',
            vaccinationCenter: 'Centre de vaccination',
            vaccinationPoint: 'Point de vaccination',
            admin: 'Administration',
            stock: 'Stocks'
        },
        common: {
            stockEntry: 'Entrée en stock',
            preparation: 'Préparation / Expédition',
            scrapping: 'Mise en déchet',
            replenishmentList: 'Liste des demandes',
            tdb: 'Tableau de bord',
            movementHistory: 'Historique des mouvements',
            myStocks: 'Mon stock',
            procurement: 'Allocation',
            usage: 'Déclaration d\'administration',
            useDeclaration: 'Suivi des administrations',
        }
    },
    loginPage: {
        title: 'Promedeo',
        description: 'Merci de vous identifier',
        stayConnected: 'Rester connecté',
        forgottenPassword: 'Mot de passe oublié ?',
        connectBtn: 'Se connecter'
    },
    common: {
        dashboard: {
            productStatLabel: {
                enterStock: 'Entrée en stock',
                preparation: 'Préparation',
                exitFridge: 'Sortie de frigo',
                scrapping: 'Mise en déchet',
                availableStock: 'Stock à date : ',
                day: '24 h glissantes',
                week: '7 j glissants',
                month: '30 j glissants'
            }
        },
        movementHistory: {
            search: {
                bl: 'N° bon de livraison',
                destination: 'Centre de destination',
                movementDate: 'Date du mouvement',
                startDate: 'Date de début',
                startTime: 'Heure de début',
                endDate: 'Date de fin',
                endTime: 'Heure de fin'
            },
            table: {
                creationDate: 'Date',
                movementType: 'Type de mouvement',
                numBl: 'Numéro de bon de livraison',
            }
        },
        scrapping: {
            scrappingCause: 'Cause de mise en déchet',
            scrappingComment: 'Commentaire',
            scrappingOtherOption: 'Autre',
            formSection: {
                scrapping: 'Mise en déchet',
                product: '1. Sélectionner le produit',
                receiving: '2. Identifier le lot',
                preparation: '2. Préparer la mise en déchet'
            },
            form: {
                unitProduct: 'Conditionnement',
            },
            stockNotFound: 'Aucun lot ne possède ce produit',
            success: 'La mise en déchet a bien été prise en compte',
        },
        enterStock: {
            pageTitle: 'Nouvelle entrée en stock',
            success: 'L\'entrée en stock a bien été enregistrée',
            warningIncoherentQuantity: 'Si la quantité reçue diffère de la quantité affichée, veuillez procéder à une mise en déchet',
            blNotFound: 'Aucun bon de livraison n\'a été trouvé',
            formSection: {
                origin: 'Sélectionner la provenance du stock',
                confirm: 'Confirmer la réception',
                referent: {
                    bl: 'Sélectionner le numéro de bon de livraison',
                    product: 'Informations sur les produits'
                },
                external : {
                    product: 'Sélectionner le produit',
                    receiving: 'Identifier le lot',
                    quantity: 'Saisir l\'entrée en stock'
                },
            },
            form: {
                origin: 'Provenance',
                compliantReception: 'Réception conforme',
                comment: 'Commentaire',
                containerPhrasing: '(Contenant : 1 boîte de {nbUnitesBoite} {typeConditionnement}{nbUnitesBoite, plural, one {} other {s}})',
                enterDatetime: 'Date et heure',
                enterDate: 'Date',
                enterTime: 'Heure',
                expirationMonth: 'Mois de péremption',
                expirationYear: 'Année de péremption',
                isTransfert: 'Transfert de stock',
                transfertType: 'Type de transfert',
                limit20Datetime: 'Date limite de conservation à -20°C',
                limit20Date: 'Date limite de conservation à -20°C',
                limit20Time: 'Heure limite de conservation à -20°C',
                limitDatetime: 'Date limite d\'utilisation',
                limitTime: 'Date limite d\'utilisation',
                exitTime: 'Heure limite d\'utilisation',
                referentBl: 'Numéro de bon de livraison',
            }
        },
        exitStock: {
            pageTitle: 'Nouvelle préparation',
            formSection: {
                preparation: '1. Information sur les produits',
                shipment: '2. Définir l\'expédition'
            },
            form: {
                isDefrosted: 'Avec action de décongélation',
                destinationComment: 'Informations complémentaires',
                container: 'Contenant',
                containerNumber: 'N° contenant',
                tracker: 'Trackeur température',
                trackerNumber: 'N° trackeur'
            },
            dialogAddProduct: {
                title: 'Ajouter un produit',
                formSection: {
                    product: '1. Sélectionner le produit',
                    preparation: '2. Information sur le produit'
                },
                form: {
                    defrosted: 'Produit décongelé',
                    refExterne: 'Référence externe',
                    exitDate: 'Date de sortie du congélateur',
                },
                stockNotFound: 'Pas de stock pour ce produit'
            },
            dialogDefrostedProduct: {
                title: 'Des produits décongelés sont disponibles (retour, transfert et/ou provenant de mon centre parent)',
                content: 'Des produits décongelés (retour, transfert et/ou provenant de mon centre parent) sont présents pour ce produit, souhaitez-vous les utiliser ?'
            },
            dialogLabelSelection: {
                title: 'Génération des étiquettes',
                lineDetails: '{nom} - {lot} {quantite} {modeQte}{modeQte, plural, one {} other {s}}',
                lot: 'Lot {lot} -',
                fields: {
                    boxes: 'Boîtes',
                    bottles: 'Unités'
                },
                remaining: '{count} {count, plural, one {unité restante} other {unités restantes}}'
            },
            print: {
                cancelPreparationButtonLabel: 'Annuler la préparation',
                cancelledPreparationWarning: 'Cette préparation a été annulée.',
                disabledFollowUp: 'Le suivi a été désactivé.',
                dialog: {
                    title: 'Confirmation d\'annulation d\'une préparation',
                    content: 'Êtes-vous sûr de vouloir annuler cette préparation ? L\'annulation d\'une préparation remet automatiquement le stock du produit à jour avec les quantités annulées.',
                },
                cancelSuccess: 'L\'annulation de la préparation a bien été effectuée',
                cancelError: 'Une erreur est survenue, l\'annulation a échoué',
            },
        },
        offlineMode: {
            bannerOffline: 'Nous ne détectons pas de connexion, vous pouvez travailler en mode déconnecté mais rien n\'envoyer',
            bannerOnline: 'Nous détectons une connexion, vous pouvez envoyer vos mouvements de stock en attente'
        }
    },
    global: {
        error: {
            validation: {
                required: 'Champ obligatoire',
                triggered: 'Un ou plusieurs champs sont incorrects',
                startDateAfterEndDate: 'La date de début ne peut pas être antérieure à celle de fin',
                endDateBeforeStartDate: 'La date de fin ne peut pas être antérieure à celle de début',
                dateLaterToNow: 'La date ne peut pas être postérieure à maintenant',
                datePriorToNow: 'La date ne peut pas être antérieure à maintenant',
                datePriorToCurrentMonth: 'La date ne peut pas être antérieure au mois actuel',
                weekDifferents: 'Les semaines ne peuvent pas être différente',
                datePriorToReceipt: 'La date ne peut pas être antérieure à la date de réception',
                unstability: 'L\'intégrité du produit ne sera pas garantie de sa réception jusque cette date',
                positive: 'La valeur doit être positive',
                tooLong: 'Le champ ne peut excéder 250 caractères',
                emailFormat: 'Le format de l\'email n\'est pas réglementaire',
                oneOfTwo: 'Veuillez renseigner au moins un des deux champs',
                oneOfThree: 'Veuillez renseigner au moins un des trois champs',
                moreThanCurrentStock: 'La quantité renseignée excède le stock actuel',
                invalidQuantity: 'La quantité renseignée est invalide',
            },
            occured: 'Une erreur est survenue',
            formError: 'Votre formulaire comporte des erreurs',
            notFound: 'Aucun résultat trouvé',
            tooSmall: 'Cette page n\'est pas disponible dans votre résolution d\'écran',
            quantityMax: 'La quantité saisie est supérieure au stock',
            quantityMaxRows: 'La quantité saisie est supérieure au stock pour les produits :',
            downloadFailed: 'Le téléchargement a échoué',
            batchNotFound: 'Lot non trouvé'
        },
        no_results: 'Aucun résultat ne correspond à votre recherche',
        status: {
            label: 'Statut',
            enabled: 'Actif',
            disabled: 'Inactif',
            indifferent: 'Indifférent',
            confirme: 'Confirmé',
            traite: 'Traité',
            refuse: 'Refusé',
            en_attente: 'En attente'
        },
        functionnal: {
            product: 'Produit',
            dose: 'dose',
            batch: 'Lot',
            batchNumber: 'Numéro de lot',
            quantity: 'Quantité',
            expiration: 'Péremption',
            totalCount: 'Soit {count} {type}{count, plural, one {} other {s}}',
            totalCountExplicit: 'Soit {count} {type}{count, plural, one {} other {s}} au total',
            confirmCount: '(confirmée : {count} {type}{count, plural, one {} other {s}})',
            conditioningType: ' {conditioningType}{count, plural, one {} other {s}}',
            fromDateToDate: 'Du {from} au {to}',
            weekFromDateToDate: 'Semaine du {from} au {to}',
            exitTime: 'Heure de sortie du congélateur',
            usageLimitDate: 'Date limite d\'utilisation',
            usageLimitTime: 'Heure limite d\'utilisation',
            vaccinationCenter: 'Centre de vaccination',
            destination: 'Destination',
            carrier: 'Transporteur',
        },
        space: ' ',
        noOptions: 'Aucun résultat',
        ellipsis: '...',
    },
    serviceWorker: {
        addApp: {
            title: 'Ajouter l\'application Promedeo sur l\'écran d\'accueil',
            btn: 'Ajouter'
        },
        update: {
            title: 'Nouvelle version disponible',
            description: 'Une nouvelle version est disponible, vous devez mettre à jour votre version pour continuer d\'utiliser l\'application.',
            btn: 'Mettre à jour'
        }
    },
    dispatch: {
        stock: {
            unitProduct: 'Unité',
            quantity: 'Qté en unité',
            productReturn: 'Retour de produit',
            formSection: {
	            productID: '1. Sélectionner le produit'
            },
            dialogReturnProduct: {
                title: 'Retours de produits et transferts de produits décongelés disponibles',
                content: 'Des retours et des transferts de vaccins décongelés sont présents pour ce produit, souhaitez-vous les utiliser ?'
            }
        },
        enterStock: {
            isTransfert: 'Transfert de stock'
        },
        exitStock: {
            replenishmentComment: 'Commentaire du centre de vaccination',
            doseTotal: 'Soit {count} doses',
            disableFollowUp: 'Désactiver le suivi',
            formSection: {
                replenishment: 'Informations sur la demande'
            },
            dialogTitle: 'Suivi désactivé',
            dialogDescription: 'Lorsque le suivi est désactivé, le stock n’est pas suivi dans le centre de vaccination de destination : le stock est considéré consommé lorsqu’il est envoyé.',
            dialogButtonLabel: 'J\'ai compris',
            dialogProcurementWarning: {
                title: 'Confirmation de la préparation',
                content: 'La quantité saisie (en prenant compte des quantités en demande) est supérieure à la quantité en allocation pour ce centre sur la semaine.\nConfirmez-vous la préparation ?'
            },
            table: {
                header: {
	                exitDatetime: 'Sortie congélateur'
                },
                action: {
                    addProduct: '+ Ajouter un produit'
                }
            },
        },
        replenishmentList: {
            search: {
                receptionDate: 'Date de réception',
                creationDate: 'Date de création',
                startDate: 'Date de début',
                startTime: 'Heure de début',
                endDate: 'Date de fin',
                endTime: 'Heure de fin',
                dateRole: 'Type de date',
                labelType: 'Type d\'étiquettes du centre de vaccination',
                labelText: 'Valeur de l\'étiquette'
            },
            wantedDate: 'Date de réception souhaitée',
            coveragePeriod: 'Période de couverture',
            confirmDate: '(confirmée: {date})',
            creationDate: 'Date de création',
            status: 'Statut',
            quantity1: '1ère injection : ',
            quantity2: '2ème injection : ',
            quantityR: 'injection rappel : ',
            conditioningType: ' dose{count, plural, one {} other {s}}',
            dispatchComment: 'Commentaire dispatch',
            destinationComment: 'Commentaire vaccination',
            pointComment: 'Commentaire point',
            destination: 'Centre',
            point: 'Point',
            expedition: 'Expéditeur',
            showFinished: 'Voir les demandes traitées',
            cancelSuccess: 'La demande a bien été annulée',
            updateStatusSuccess: 'Le statut de la demande a bien été modifié',
            updateMultipleStatusSuccess: 'Les statuts des demandes ont bien été modifiés',
            updateStatusError: 'Une erreur est survenue, le statut de la demande n\'a pas été modifié',
            updateMultipleStatusError: 'Une erreur est survenue, les statuts des demandes n\'ont pas été modifiés',
            preparationSuccess: 'Les préparations ont bien été effectuées',
            preparationError: 'Une erreur est survenue, les préparations n\'ont pas été effectuées',
            actions: {
                process: 'Traiter',
                processPoint: 'Traité',
                prepareReplenishment: 'Préparer',
                confirm: 'Confirmer',
                refuse: 'Refuser'
            },
            dialog: {
                multipleBtn: 'Gestion multiple',
                refuse: {
                    title: 'Refuser la demande de réapprovisionnement',
                    comment: 'Raison du refus'
                },
                cancel: {
                    title: 'Annuler la demande de réapprovisionnement',
                    comment: 'Commentaire d\'annulation',
                },
                process: {
                    quantiteConfirmee: 'Quantité confirmée : {quantity} {conditioningType}{quantity, plural, one {} other {s}}',
                    dateConfirmee: 'Date confirmée : {date}'
                },
                procurement: {
                    action: 'Allocation',
                    title: 'Allocation',
                    center: 'Centre : {centerName}',
                    product: 'Produit : {productName} ({conditioningType})',
                    lundi: 'Lundi {date}',
                    mardi: 'Mardi {date}',
                    mercredi: 'Mercredi {date}',
                    jeudi: 'Jeudi {date}',
                    vendredi: 'Vendredi {date}',
                    samedi: 'Samedi {date}',
                    dimanche: 'Dimanche {date}',
                    total: 'Soit {quantity} {conditioningType}{quantity, plural, one {} other {s}} sur la semaine.',
                    residualPrevision: 'Allocation résiduelle :  {quantity} {conditioningType}{quantity, plural, one {} other {s}} sur la semaine.',
                    residualPrevisionExcept: 'Allocation résiduelle :  {quantity} {conditioningType}{quantity, plural, one {} other {s}} sur la semaine. (hors demande sélectionnée)'
                },
                prepare: {
                    title: 'Préparation des demandes',
                    form: {
                        product: '1. Produit sélectionné',
                        preparation: '2. Informations sur la préparation',
                        following: '3. Suivi du produit'
                    },
                    button: 'Préparer les demandes',
                    error: {
                        oneProduct: 'Les demandes ne doivent concerner qu\'un seul produit',
                        status: 'Les demandes ne doivent pas être déjà traitées ou refusées'
                    }
                },
                cancelConfirmation: {
                    title: 'Annuler la confirmation de réapprovisionnement',
                    message: 'Êtes-vous sûr de vouloir annuler la confirmation de cette demande de réapprovisionnement ?'
                },
                departure: {
                    button: 'Départ marchandises',
                    errorStatus: 'Les demandes doivent être traitées'
                },
                confirm: {
                    title: 'Confirmation des demandes',
                    body: 'Via la confirmation des demandes multiples, vous vous apprêtez à confirmer l\'ensemble des demandes sélectionnées avec les quantités et dates de réception préalablement renseignées.',
                    button: 'Confirmer les demandes',
                    errors: {
                        status: 'Les demandes doivent exclusivement être en attente',
                        date: 'Les demandes doivent avoir une date de réception ultérieure à maintenant'
                    }
                }
            },
            download: {
                title: 'Téléchargement pour vos préparations',
                description: 'Vous avez procédé à multiples préparations. Vous pouvez télécharger les documents associés via le bouton ci-dessous.'
            },
            errors: {
                status: 'La mise à jour vers ce statut n\'est pas possible',
                missingField: 'Un produit et une date doivent être sélectionnées pour connaître les allocations',
                procurement: 'Ce centre ne possède pas de plan d\'approvisionnement pour la semaine indiquée',
            }
        },
        processing: {
            dialog: {
                title: 'Traitement de la demande',
                exitStockContent: 'Vous avez procédé à une préparation suite à une demande de réapprovisionnement. Souhaitez-vous passer le statut de la demande à "Traité" ?',
                exitStockContentNotify: 'Le centre de vaccination sera notifié par mail.',
                contentProduct: 'Produit : {productName}',
                contentQuantity: 'Quantité : {quantity} {typeCond}{quantity, plural, one {} other {s}}',
                contentQuantity1: 'Quantité 1ère dose : {quantity} dose{quantity, plural, one {} other {s}}',
                contentQuantity2: 'Quantité 2ème dose : {quantity} dose{quantity, plural, one {} other {s}}',
                contentQuantityR: 'Quantité dose rappel : {quantity} dose{quantity, plural, one {} other {s}}',
                contentTotalQuantity: 'Soit au total : {quantity} {typeCond}{quantity, plural, one {} other {s}}',
                contentDestinationCenter: 'Centre de destination : {destinationCenter}',
                contentCoveragePeriod: 'Periode de couverture : du {dateCouvertureDebut} à {heureCouvertureDebut} au {dateCouvertureFin} à {heureCouvertureFin}',
                carrier: 'Transporteur : {carrier}',
                comment: 'Commentaire',
            }
        },
        departure: {
            dialog: {
                title: 'Départ marchandises',
                disclaimer: 'Via le départ marchandises, vous attestez de l\'envoi des marchandises à l\'heure saisie.',
                departureDatetime: "Date de départ",
                departureDate: "Date de départ",
                departureTime: "Heure de départ",
            }
        },
        confirm: {
            dialog: {
                disclaimer: 'Via la confirmation d\'une demande, vous attestez de la bonne prise en compte de la demande. Lors de la validation, un mail notifiera le centre de vaccination.',
                title: 'Confirmation de la demande',
                receptionDatetime: "Date de réception",
                receptionDate: "Date de réception",
                receptionTime: "Heure de réception",
                comment: 'Commentaire',
                sections: {
                    initial: 'Demande initiale',
                    confirmed: 'Demande confirmée'
                },
                warningTitle: 'Confirmation de demande',
                warningDesc: 'La quantité saisie (en prenant compte des quantités en demande) est supérieure à la quantité en allocation pour le centre sur la semaine.\nConfirmez-vous la quantité confirmée ?'
            }
        },
        productReturn: {
            form: {
                sections: {
                    selectVaccinationCenter: '1. Sélectionner le centre de vaccination',
                    selectProduct: '2. Sélectionner le produit',
                    selectBl: '3. Sélectionner le numéro de bon de livraison',
                    identifyBatch: '4. Identifier le lot',
                },
                inputs: {
                    blNumber: 'Numéro de bon de livraison',
                    deliverySlipNumber: 'Numéro de bon de livraison',
                    returnComment: 'Commentaire de retour'
                },
                warning: 'La quantité pré-remplie est la quantité résiduelle présente dans le centre de vaccination',
                blListNotFound: 'Aucun BL ne correspond à ce centre et produit'
            },
            dialog: {
                title: 'Confirmation du retour de produit',
                content: 'La quantité saisie est supérieure à la quantité résiduelle présente dans le centre de vaccination. Confirmez-vous la quantité en retour ?'
            }
        }
    },
    center: {
        name: 'Nom',
        type: 'Type',
        status: 'Statut',
        numFiness: 'Numéro finess',
        service: 'Service',
        gestionStock: 'Gestion du stock',
        desactivationSuivi: 'Désactivation du suivi par défaut',
        modeOffline: 'Mode déconnecté activé',
        siret: 'Siret',
        ape: 'APE',
        complementDistrib: 'Complément de distribution',
        libelleRoutage: 'Libellé routage',
        telephone: 'Téléphone',
        email: 'Email',
        capacite: 'Capacité',
        adresse: 'Adresse',
        codePostal: 'Code postal',
        ville: 'Ville',
        referent: 'Référent',
        users: 'Rechercher un utilisateur',
        childrenCenters: 'Centres',
        tags: 'Etiquettes',
        notifyReasons: 'Notifier le(s) destinataire(s) pour',
        destinataireEmail: 'Destinataire des mails',
        modeQte: 'Mode de saisie des allocations',
        alertRef: 'Ce centre est rattaché à {centerNames}',
        alertNoDispatch: 'Attention, ce centre n\'est rattaché à aucun centre de dispatch',
        alertNoVaccination: 'Attention, ce centre n\'est rattaché à aucun centre de vaccination',
        missingType: 'Sélectionnez le type de centre avant de pouvoir saisir les préférences.',
        defaultCarrier: 'Transporteur par défaut',
        modeAdmin: 'Mode de saisie d\'administration',
        dialog: {
            title: 'Ajouter une catégorie',
            category: 'Catégorie',
            categoryLabel: 'Nom de la catégorie',
            value: 'Valeur',
            tagAlreadyExists: 'Cette catégorie est déjà utilisée',
            actions: {
                cancel: 'ANNULER',
                confirm: 'AJOUTER'
            }
        },
        formSection: {
            information: 'Informations du centre',
            adresse: 'Adresse',
            users: 'Utilisateurs',
            childrenCenters: 'Centres associés',
            preferences: 'Préférences'
        },
        subSections: {
            mails: 'Mails',
            templates: 'Templates'
        },
        updateStatusSuccess: 'Le statut du centre a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du centre n\'a pas été modifié',
        updateSuccess: 'Le centre a bien été modifié',
        updateError: 'Une erreur est survenue, le centre n\'a pas été modifié',
        createSuccess: 'Le centre a bien été créé',
        createError: 'Une erreur est survenue, le centre n\'a pas été créé',
        pageTitle: {
            create: 'Nouveau centre',
            update: 'Modifier un centre'
        },
        notUniqueNumFiness: 'Le numéro finess doit être unique'
    },
    tracker: {
        name: 'Nom',
        manufacturer: 'Fabricant',
        type1: 'Type 1',
        type2: 'Type 2',
        temperatureInterval: 'Plage de température gérée',
        fabricant: 'Fabricant',
        nom: 'Nom',
        minTemperature: 'Température minimum',
        maxTemperature: 'Température maximum',
        updateStatusSuccess: 'Le statut du tracker a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du tracker n\'a pas été modifié',
        updateSuccess: 'Le tracker a bien été modifié',
        updateError: 'Une erreur est survenue, le tracker n\'a pas été modifié',
        createSuccess: 'Le tracker a bien été créé',
        createError: 'Une erreur est survenue, le tracker n\'a pas été créé',
        formSection: {
            information: 'Informations du tracker',
            temperatureLimit: 'Limites de température',
        },
        pageTitle: {
            create: 'Nouveau tracker',
            update: 'Modifier un tracker'
        },
        notUniqueNom: 'Le couple fabricant/nom doit être unique'
    },
    container: {
        name: 'Nom',
        manufacturer: 'Fabricant',
        type: 'Type',
        size: 'Taille',
        weight: 'Poids',
        capacity: 'Capacité',
        fabricant: 'Fabricant',
        nom: 'Nom',
        taille: 'Taille',
        poids: 'Poids',
        capacite: 'Capacité',
        updateStatusSuccess: 'Le statut du contenant a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du contenant n\'a pas été modifié',
        updateSuccess: 'Le contenant a bien été modifié',
        updateError: 'Une erreur est survenue, le contenant n\'a pas été modifié',
        createSuccess: 'Le contenant a bien été créé',
        createError: 'Une erreur est survenue, le contenant n\'a pas été créé',
        formSection: {
            information: 'Informations du contenant',
            characteristics: 'Caractéristiques',
        },
        pageTitle: {
            create: 'Nouveau contenant',
            update: 'Modifier un contenant'
        },
        notUniqueNom: 'Le couple fabricant/nom doit être unique'
    },
    carrier: {
        name: 'Nom',
        centerName: 'Centre',
        siret: 'Siret',
        address: 'Adresse',
        phone: 'Téléphone',
        contact: 'Contact',
        nom: 'Nom',
        adresse: 'Adresse',
        complementAdresse: 'Complément d\'adresse',
        codePostal: 'Code postal',
        ville: 'Ville',
        pays: 'Pays',
        telephone: 'Téléphone',
        mailContact: 'Email',
        updateStatusSuccess: 'Le statut du transporteur a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du transporteur n\'a pas été modifié',
        updateSuccess: 'Le transporteur a bien été modifié',
        updateError: 'Une erreur est survenue, le transporteur n\'a pas été modifié',
        createSuccess: 'Le transporteur a bien été créé',
        createError: 'Une erreur est survenue, le transporteur n\'a pas été créé',
        formSection: {
            information: 'Informations du transporteur',
            address: 'Adresse',
            coordinates: 'Coordonnées',
        },
        pageTitle: {
            create: 'Nouveau transporteur',
            update: 'Modifier un transporteur'
        },
        notUniqueNom: 'Le nom doit être unique',
        notUniqueSiret: 'Le siret doit être unique',
        dispatchCenter: 'Centre de dispatch'
    },
    product: {
        type: 'Type de produit',
        name: 'Nom',
        codeCip: 'Code CIP',
        codeUcd: 'Code UCD',
        formePharma: 'Forme pharmaceutique',
        preservation: 'Conservation',
        typeCond: 'Type de conditionnement',
        nbunitBoite: 'Nombre d\'unités par boîte',
        nbDoses: 'Nombre de doses',
        nomCommercial: 'Nom commercial',
        nom: 'Nom',
        surveillanceRenforcee: 'Surveillance renforcée',
        substanceActive1: 'Substance active #1',
        substanceActive2: 'Substance active #2',
        voieAdministration: 'Voie d\'administration',
        peremption: 'Format date de péremption',
        conservation: 'Conservation',
        stabilite28: 'Durée de stabilité (h) entre 2 et 8°C',
        stabiliteMiseTemperature: 'Durée de stabilité (h) mise température',
        stabiliteReconstitution: 'Durée de stabilité (h) reconstitution',
        stabilite1erPrelevement: 'Durée de stabilité (h) 1er prélèvement',
        stabilite28TransfertMoins20: 'Durée de stabilité (h) 2-8°C (Transfert -20°C)',
        dureeDecongelation: 'Durée de décongélation (h)',
        typeConditionnement: 'Type de conditionnement',
        nbUnitesBoite: 'Nombre d\'unités par boite',
        commentaireBl: 'Commentaire bon de livraison',
        updateSuccess: 'Le produit a bien été modifié',
        updateError: 'Une erreur est survenue, le produit n\'a pas été modifié',
        updateStatusSuccess: 'Le statut du produit a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du produit n\'a pas été modifié',
        createSuccess: 'Le produit a bien été créé',
        createError: 'Une erreur est survenue, le produit n\'a pas été créé',
        nbDosesError: 'Le nombre de doses doit contenir trois chiffres entiers maximum et ne peut avoir qu\'une seule décimale',
        formSection: {
            information: 'Informations du produit',
            constitution: 'Constitution',
            conservation: 'Conservation',
            conditioning: 'Conditionnement',
        },
        pageTitle: {
            create: 'Nouveau produit',
            update: 'Modifier un produit'
        },
        notUniqueCip: 'Le code CIP doit être unique'
    },
    procurement: {
        title: 'Allocation',
        fieldsLabel: {
            vaccinationLocation: 'Lieu de vaccination',
            date: 'Semaine',
            modeQteLabel: 'Afficher en :',
            modeQte: 'Dose / Flacon'
        },
        listLabels: {
            vaccinationLocation: 'Lieu de vaccination',
            total: 'Soit en doses par semaine',
            conditioningType: 'Type de conditionnement'
        },
        add: {
            sections: {
                weekSelect: '1. Sélection de la semaine',
                centerSelect: '2. Sélection du centre',
                productSelect: '3. Sélection du produit',
                quantityTyping: '4. Saisie de l\'allocation',
            },
            noProductsAvailable: 'Aucun produit à ajouter.'
        },
        warning: 'Attention, ceci n\'est qu\'une allocation. Cela n\'exclue en rien la saisie d\'une demande lorsque vous souhaitez un réapprovisionnement',
        updateSuccess: 'L\'allocation a bien été modifiée',
        countDoses: 'Soit {count} {type}{count, plural, one {} other {s}} pour la semaine',
        createReplenishment: 'Générer les demandes',
        import: 'Importer les allocations',
        createReplenishmentSuccess: 'La génération des demandes a été effectuée',
        createReplenishmentError: 'Une erreur est survenue, la génération n\' a pas été effectuée',
        export: {
            1: 'Détaillé sur une semaine',
            2: 'Détaillé sur deux semaines',
            5: 'Détaillé sur cinq semaines'
        },
        exportCumul: {
            1: 'Cumulé sur une semaine',
            2: 'Cumulé sur deux semaines',
            5: 'Cumulé sur cinq semaines'
        },
        duplicate: {
            title: 'Duplication d\'une allocation',
            infoSection: 'Informations d\'allocation',
            weeksSection: 'Duplication sur les semaines suivantes',
            weeksInfo: 'Vous souhaitez dupliquer l\'allocation sur les semaines suivantes. Sur combien de semaines souhaitez-vous dupliquer ?',
            nbWeeks: 'Nombre de semaines',
            weeksMax: 'Vous ne pouvez pas duppliquer au delà de 5 semaines',
            btn: 'Dupliquer',
            errorExistingProcurement: 'Une allocation existe déjà dans les semaines à venir',
            success: 'L\'allocation a bien été dupliquée',
            error: 'Une erreur est survenue, l\'allocation n\'a pas été dupliquée'
        },
        quantity: {
            Q: 'Quantité',
            D1: 'D1',
            D2: 'D2'
        },
        importDialog: {
            title: 'Import des allocations',
            general: 'Informations sur l\'import',
            file: 'Données à importer',
            warning: 'Attention, l\'import d\'un nouveau fichier écrasera les données déjà présentes pour les semaines importées.',
            model: 'Modèle : ',
            fields: {
                period: 'Type d\'import',
                qtyMode: 'Type de conditionnement',
                startWeek: 'Semaine de départ',
                file: 'Fichier'
            },
            errors: {
                title: 'Erreurs d\'import',
                line: 'Ligne {line} : {errorMessage}',
                emptyFiness: 'numéro finess vide',
                emptyProduit: 'produit vide',
                notFoundFiness: 'numéro finess inconnu',
                notFoundProduit: 'produit inconnu',
                csvVide: 'Le fichier est vide'
            },
            success: 'Vos allocations ont été importées',
            error: 'Erreur lors de l\'import, vous allocations n\'ont pas été importées',
            confirm: 'Importer'
        }
    },
    administrationTracking: {
        filters: {
            location: 'Lieu de vaccination',
            administrationMode: 'Mode d\'administration',
            week: 'Semaine d\'administration',
            startWeek: 'Semaine de départ'
        },
        headers: {
            location: 'Lieu de vaccination'
        },
        fields: {
            mode: {
                label: 'Afficher par',
                value: 'Semaine / Jour'
            }
        }
    } ,
    template: {
        fieldsLabel: {
            nom: 'Nom',
            type: 'Type',
            statut: 'Statut',
            publie: 'Afficher uniquement les templates publiés',
            published: 'Publié',
            unpublished: 'Masqué',
            creationDate: 'Date de création',
            creator: 'Créateur',
            file: 'Fichier'
        },
        create: {
            formSection: {
                information: 'Informations'
            }
        },
        alert: {
            fileInfoCreate: 'Le fichier doit être au format .docx.',
            fileInfoUpdate: 'Ce fichier (si renseigné), écrasera le précédent. Le fichier doit être au format .docx.'
        },
        category: {
            referent: 'Templates référent',
            dispatch: 'Templates dispatch',
            vaccination: 'Templates vaccination',
        },
        createTemplateSuccess: 'Le template a bien été créé',
        createTemplateError: 'Une erreur est survenue, le template n\'a pas pu être créé',
        updateTemplateSuccess: 'Le template a bien été modifié',
        updateTemplateError: 'Une erreur est survenue, le template n\'a pas pu être modifié',
        updateStatusSuccess: 'Le statut du template a bien été modifié',
        updateStatusError: 'Une erreur est survenue, le statut du template n\'a pas pu être modifié',
        notUniqueNom: 'Le nom doit être unique'
    },
    vaccination: {
        enterStock: {
            deliverySlipNumber: 'Numéro de bon de livraison',
            formSection: {
                slipNumber: '1. Sélectionner le numéro de bon de livraison',
                identifyBatch: '2. Informations sur les produits',
                receptionConfirmation: '3. Confirmer la réception'
            },
            blNotFound: 'Aucun bon de livraison n\'a été trouvé',
            success: 'L\'entrée en stock a bien été enregistrée',
            successOffline: 'L\'entrée en stock a bien été sauvegardée en local',
            error: 'Une erreur est survenue, l\' entrée en stock n\'a pas été créée',
            warningIncoherentQuantity: 'Si la quantité reçue diffère de la quantité affichée, veuillez procéder à une mise en déchet',
            compliantReception: 'Réception conforme',
            comment: 'Commentaire'
        },
        exitFridge: {
            formSection: {
                batchNumber: '2. Sélectionner le lot / bon de livraison',
                exitSelection: '3. Sélectionner la sortie',
                usingProduct: '4. Saisir la quantité',
            },
            batchNumber: 'Numéro de lot / bon de livraison',
            batchNotFound: 'Aucun lot / bon de livraison ne possède ce produit',
            success: 'La sortie de frigo a bien été enregistrée',
            successOffline: 'La sortie de frigo a bien été sauvegardée en local',
            error: 'Une erreur est survenue, la sortie de frigo n\'a pas été enregistrée',
            vaccinationPoint: 'Point de vaccination',
            sortieType: {
                INTERNE: 'Interne',
                VACCINATION_POINT: 'Point de vaccination'
            },
            seringue: 'Seringue',
            flaconConsumed: 'Flacon consommé',
            flacon: 'Flacon'
        },
        declarationOfUse: {
            form: {
                sections: {
                    product: '1. Sélectionner le produit',
                    week: '2. Sélectionner la semaine d\'administration',
                    day: '2. Sélectionner le jour d\'administration',
                    oldQuantity: '3. Saisir la quantité réalisée',
                    futureQuantity: '3. Saisir la quantité allocationnelle'
                },
                fields: {
                    week: 'Semaine d\'administration',
                    date: 'Date d\'administration',
                    doseCount1: 'Nombre de doses 1ère injection',
                    doseCount2: 'Nombre de doses 2ème injection',
                    doseCount3: 'Nombre de doses rappel',
                    singleDoseCount: 'Nombre de doses uniques',
                },
                oldWarning: 'Au vu de la période saisie, vous vous apprêtez à saisir des quantités réalisées',
                futureWarning: 'Au vu de la période saisie, vous vous apprêtez à saisir des quantités allocationnelles',
            },
            success: 'La déclaration d\'administration a été enregistrée',
            error: 'Une erreur est survenue, la déclaration d\'administration n\'a pas été enregistrée'
        },
        scrapping: {
            formSection: {
                batchNumber: '2. Sélectionner le lot',
                usingProduct: '3. Saisir la quantité',
            },
            success: 'La mise en déchet a été enregistrée',
            successOffline: 'La mise en déchet a été enregistrée en local',
            error: 'Une erreur est survenue, la mise en déchet n\'a pas été enregistrée'
        },
        replenishment: {
            datetimeWanted: 'Date de réception souhaitée',
            dateWanted: 'Date de réception souhaitée',
            timeWanted: 'Heure de réception souhaitée',
            coveragePeriod: 'Période de couverture',
	        datetimeCoverageStart: 'Date de début',
	        dateCoverageStart: 'Date de début',
	        timeCoverageStart: 'Heure de début',
	        datetimeCoverageEnd: 'Date de fin',
	        dateCoverageEnd: 'Date de fin',
	        timeCoverageEnd: 'Heure de fin',
            datePriorToTomorrow: 'La date ne peut pas être antérieure à demain',
            comment: 'Commentaire',
            modeQte: 'Mode de saisie',
            quantity1: '1ère injection',
            quantity2: '2ème injection',
            quantityR: 'injection rappel',
            formSection: {
                info: '2. Renseigner le besoin',
                comment: '3. Ajouter un commentaire',
                carrier: 'Sélectionner un transporteur'
            },
            success: 'La demande de réapprovisionement a bien été enregistrée',
            error: 'Une erreur est survenue, la demande de réapprovisionement n\'a pas été enregistrée',
            finishedBtn: 'À traiter',
            showPrevision: 'Voir les allocations de la semaine',
            dialog: {
                title: 'Confirmation de réapprovisionement',
                actionNo: 'Non',
                actionYes: 'Oui',
                doYouConfirm: 'Confirmez-vous cette demande ?',
                summary: 'Vous souhaitez faire une demande de réapprovisionement de {productCount} {conditioningType}{productCount, plural, one {} other {s}} pour le {date}.'
            },
            warningTitle: 'Demande de réapprovisionnement',
            warningDesc: 'La quantité saisie (en prenant compte des quantités en demande) est supérieure à la quantité en allocation pour votre centre sur la semaine.\nConfirmez-vous la demande ?'
        },
        movementHistory: {
            search: {
                center: 'Centre de dispatch',
                bl: 'N° bon de livraison',
                destination: 'Centre de dispatch',
                movementDate: 'Date du mouvement',
                startDate: 'Date de début',
                startTime: 'Heure de début',
                endDate: 'Date de fin',
                endTime: 'Heure de fin'
            },
            table: {
                creationDate: 'Date',
                movementType: 'Type de mouvement',
                numBl: 'Numéro de bon de livraison',
            }
        },
        synchronisation: {
            emptyDataTitle: 'Synchroniser les données en local',
            receivedDataTitle: 'Données présentes en local',
            emptyData: 'Aucune donnée en local, veuillez synchroniser.',
            bl: 'BL disponibles : {bl}',
            stock: 'Stocks disponibles : {stock}',
            lastRefresh: 'Dernière synchronisation le {datetime}',
            waitingActionsTitle: 'En attente d\'envoi au serveur',
            synchroniseSuccess: 'Les données ont bien été récupérées en local. Vous pouvez effectuer des actions déconnectées.',
            waitingCard: {
                bl: 'BL : {bl}',
                comment: 'Cause : {comment}',
                bySeringue: '({quantity})',
                exitType: 'Sortie : {exitType}'
            }
        }
    },
    stockEdit: {
        dialog: {
            title: 'Modification de stock',
            stockCurrent: 'Stock actuel',
            quantity: 'Quantité (+/-)',
            quantityAfterward: 'Stock après opération',
            expiration: 'Péremption actuelle : {expiration}',
            expirationMonth: 'Mois de péremption',
            expirationYear: 'Année de péremption',
            comment: 'Commentaire',
            updateSuccess: 'Le stock a bien été modifié',
            formSection: {
                quantity: 'Gestion de la quantité',
                expiration: 'Gestion de la péremption',
                comment: 'Traçage de la modification'
            }
        },
        isVisibleOutOfStock: 'Voir les stocks épuisés',
        onlyRetour: 'Voir uniquement les retours',
        referentCenters: 'Centres référent',
        dispatchCenters: 'Centres de dispatch',
        vaccinationCenters: 'Centres de vaccination',
        dataTable: {
            centerName: 'Centre',
            usageLimit: 'Limite d\'utilisation',
            deliverySlipNumber: 'BL',
            seePrint: 'Voir la fiche de retour'
        }
    },
    mail: {
        form: {
            fieds: {
                title: {
                    label: 'Objet'
                },
                body: {
                    label: 'Mail'
                }
            },
            success: 'Le mail a bien été envoyé',
            error: 'Une erreur est survenue, le mail n\'a pas été envoyé'
        }
    },
    button: {
        test: 'Tester',
        download: 'Télécharger',
        publish: 'Publier',
        unpublish: 'Masquer',
        confirm: 'Confirmer',
        validate: 'Valider',
        cancel: 'Annuler',
        save: 'Sauvegarder',
        create: 'Créer',
        delete: 'Supprimer',
        update: 'Modifier',
        disable: 'Désactiver',
        enable: 'Activer',
        search: 'Rechercher',
        sendNeedsNotification: 'Demander un réassort',
        export: 'Exporter les résultats',
        refuse: 'Refuser',
        return: 'Retour',
        send: 'Envoyer',
        sendError: 'Erreurs',
        yes: 'Oui',
        no: 'Non',
        print: {
            initial: 'Imprimer',
            BL: 'Fiche de suivi',
            label: 'Etiquettes d\'expédition',
            labelScrapping: 'Etiquette déchet',
            bottle36: 'Etiquettes de flacon 36',
            contentLabel: 'Etiquette contenant',
            return: 'Fiche de retour'
        }
    },
    select: {
        all: 'Tous',
        none: 'Aucun',
        default: 'Par défaut'
    },
    enum: {
        centerType: {
            REFERENT: 'Centre référent',
            DISPATCH: 'Centre de dispatch',
            VACCINATION: 'Centre de vaccination',
            VACCINATION_POINT: 'Point de vaccination'
        },
        movementType: {
            ENTREE: 'Entrée en stock',
            REASSORT: 'Réassort',
            TRANSFERT: 'Transfert',
            PREPARATION: 'Préparation / Expédition',
            SORTIE_FRIGO: 'Sortie interne',
            SORTIE_POINT: 'Envoi vers point',
            DECHET: 'Mise en déchet',
            CORRECTION: 'Correctif de stock',
            RETOUR: 'Retour de produit',
            MODIF_PEREMPTION: 'Modification de péremption'
        },
        days: {
            MONDAY: 'Lundi',
            TUESDAY: 'Mardi',
            WEDNESDAY: 'Mercredi',
            THURSDAY: 'Jeudi',
            FRIDAY: 'Vendredi',
            SATURDAY: 'Samedi',
            SUNDAY: 'Dimanche',
        },
        months: {
            JANUARY: 'Janvier',
            FEBRUARY: 'Février',
            MARCH: 'Mars',
            APRIL: 'Avril',
            MAY: 'Mai',
            JUNE: 'Juin',
            JULY: 'Juillet',
            AUGUST: 'Août',
            SEPTEMBER: 'Septembre',
            OCTOBER: 'Octobre',
            NOVEMBER: 'Novembre',
            DECEMBER: 'Décembre',
        },
        replenishmentStatus: {
            EN_ATTENTE: 'En attente',
            TRAITE: 'Traité',
            DEPART_MARCHANDISES: 'Départ marchandises',
            CONFIRME: 'Confirmé',
            REFUSE: 'Refusé',
        },
        dateRole: {
            creation: 'Date de création',
            reception: 'Date de réception'
        },
        receiverMail: {
            REFERENT: 'Référent',
            CENTER: 'Email du centre',
            BOTH: 'Les deux'
        },
        tagCategories: {
            TRANSPORTEUR: 'Transporteur',
            TYPE_CENTRE: 'Type de centre',
            TOURNEE: 'Tournée',
            Secteur: 'Tournée',
            AUTRE: 'Autre'
        },
        tagCategoriesLabel: {
            TRANSPORTEUR: 'Transporteur : {value}',
            TYPE_CENTRE: 'Type de centre : {value}',
            TOURNEE: 'Tournée : {value}',
            SECTEUR: 'Secteur : {value}',
            AUTRE: 'Autre ({label}) : {value}'
        },
        mailReasons: {
            RECEPTION_STOCK_TO_REFERENT: 'Réception du stock par le centre enfant',
            BESOIN_APPROVISIONNEMENT: 'Besoin d\'approvisionnement',
            BESOIN_APPROVISIONNEMENT_POINT: 'Besoin d\'approvisionnement d\'un point',
            RECEPTION_STOCK_VACCINATION: 'Réception du stock par le centre de vaccination',
            TRAITEMENT_APPROVISIONNEMENT: 'Traitement de l\'approvisionnement',
            CONFIRMATION_APPROVISIONNEMENT: 'Confirmation d\'approvisionnement',
            REFUS_APPROVISIONNEMENT: 'Refus d\'approvisionnement',
            ANNULATION_APPROVISIONNEMENT: 'Annulation d\'approvisionnement',
            ANNULATION_APPROVISIONNEMENT_POINT: 'Annulation d\'approvisionnement d\'un point'
        },
        templateTypes: {
            REFERENT_BL: 'Bordereau de livraison',
            REFERENT_ETIQUETTE: 'Etiquettes d\'expédition',
            REFERENT_FLACON: 'Etiquettes de flacon',
            REFERENT_ETIQUETTE_CONTENANT: 'Etiquette contenant',
            REFERENT_DECHET: 'Etiquette déchet référent',
            DISPATCH_BL: 'Fiche de suivi',
            DISPATCH_ETIQUETTE: 'Etiquettes d\'expédition',
            DISPATCH_FLACON_36: 'Etiquettes de flacon 36',
            DISPATCH_ETIQUETTE_CONTENANT: 'Etiquette contenant',
            DISPATCH_DECHET: 'Etiquette déchet dispatch',
            DISPATCH_RETOUR: 'Fiche de retour',
            VACCINATION_ETIQUETTE: 'Etiquette d\'expédition point',
            VACCINATION_DECHET: 'Etiquette déchet vaccination'
        },
        qtyMode: {
            DOSE: 'Par dose',
            CONDITIONNEMENT: 'Par flacon'
        },
        exitMode : {
            FLACON: 'Par flacon',
            SERINGUE: 'Par seringue'
        },
        productTypes: {
            VACCIN: 'Vaccin'
        },
        transfertType: {
            MOINS_20: '-70°C => -20°C',
            CONGELE: 'Vaccins congelés',
            DECONGELE: 'Vaccins décongelés'
        },
        transfertTypeInStock: {
            MOINS_20: '-70°C => -20°C',
            CONGELE: 'Transfert congelé',
            DECONGELE: 'Vaccins décongelés'
        },
        adminMode: {
            JOUR: 'Par jour',
            SEMAINE: 'Par semaine'
        },
        procurementImportType: {
            JOUR: 'Sur 35 jours',
            SEMAINE: 'Sur 10 semaines'
        },
        typeCondPoint: {
            SERINGUE: 'seringue'
        },
        expirationType: {
            JOUR: 'En jour',
            MOIS: 'En mois'
        },
        origin: {
            REFERENT: 'Par mon référent',
            EXTERNAL: 'Externe'
        },
        waitingAction: {
            ENTRY: 'Entrée en stock',
            EXIT: 'Sortie de frigo',
            SCRAPPING: 'Mise en déchet',
            UPDATE_STOCK: 'Mise à jour stock'
        }
    },

    // Intégration clé isotope
    password: {
        errors: {
            no_match: 'Le mot de passe et la confirmation ne correspondent pas',
            odlPasswordFailed: 'Le mot de passe ne correspond pas à l\'actuel',
            newPasswordContainsOld: 'Le mot de passe ne peut contenir l\'ancien',
            containsInfo: 'Le mot de passe ne peut contenir vos informations personnelles',
            nbCharacters: 'Le mot de passe doit contenir au moins {0} caractères',
            formatPassword: 'Le mot de passe doit contenir au moins ' +
                '{0} minuscule{0, plural, =0 {} one {} other {s}}, {1} majuscule' +
                '{1, plural, =0 {} one {} other {s}}, {2} chiffre' +
                '{2, plural, =0 {} one {} other {s}} et {3} ' +
                '{2, plural, =0 {caractère spécial} one {caractère spécial} other {caractères spéciaux}}'
        }
    },
    TABLE_SELECT_ALL: 'Sélectionnez tout',
    isotope: {
        datatable: {
            header: {
                selected: '{numSelected, plural, =0 {Aucun élément sélectionné} one {1 élément sélectionné} other {# éléments sélectionnés}}'
            },
            footer: {
                elementsPerPage: 'Éléments par page :',
                pagination: '{startIndex, number} - {endIndex, number} sur {total, number}'
            }
        },
        forgottenUserPassword: {
            dialog: {
                title: 'Mot de passe oublié',
                success: 'Votre demande a bien été prise en compte. Un email a été envoyé à votre adresse',
                description: 'Pour réinitialiser votre mot de passe, veuillez saisir votre adresse e-mail.',
                login: 'Identifiant',
                email: 'Email'
            },
            changePassword: {
                title: 'Modification du mot de passe',
                password: 'Mot de passe',
                confirmPassword: 'Confirmation du mot de passe',
                errorMatchingEmail: 'La confirmation de mot de passe est différente du mot de passe',
                success: 'La modification du mot de passe a bien été effectuée'
            }
        }
    },
    valueList: {
        recherche: 'Recherche',
        valeurs: 'Valeurs',
        form: {
            name: 'Nom',
            shortcut: 'Code',
            description: 'Description'
        },
        entries: {
            valeurs: 'Valeurs',
            actif: 'Actif',
            inactif: 'Inactif'
        },
        popin: {
            add: 'Ajouter une valeur',
            edit: 'Editer une valeur',
            code: 'Code',
            inactif: 'Inactif',
            langue: 'Libellé {codeLang}'
        }
    },
    users: {
        lastname: 'Nom',
        firstname: 'Prénom',
        login: 'Identifiant',
        email: 'Mail',
        phoneNumber: 'Téléphone',
        company: 'Société',
        profile: 'Profil',
        status: {
            header: 'Statut',
            active: 'Actif',
            inactive: 'Inactif'
        },
        query: 'Recherche textuelle',
        showDisabled: 'Afficher les utilisateurs désactivés',
        search: 'Rechercher',
        selectAll: 'Tous',
        password: 'Mot de passe',
        passwordConfirm: 'Confirmation du mot de passe',
        actions: {
            updateStatusSuccess: 'Le statut de l\'utilisateur a bien été modifié',
            updateStatusError: 'Une erreur est survenue, le statut de l\'utilisateur n\'a pas été modifié',
            updateSuccess: 'L\'utilisateur a bien été modifié',
            createSuccess: 'L\'utilisateur a bien été créé',
            errors: {
                loginExists: 'Le login existe déjà',
                emailExists: 'Le mail existe déjà'
            }
        },
        form: {
            titleUpdate: 'Modification d\'un utilisateur',
            titleCreate: 'Ajout d\'un utilisateur'
        }
    },
    btn: {
        save: 'Enregistrer',
        otherSave: 'Sauvegarder',
        edit: 'Modifier',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        validate: 'Valider',
        return: 'Retour',
        import: 'Importer',
        export: 'Exporter',
        delete: 'Supprimer',
        logout: 'Déconnexion',
        account: 'Mon Compte',
        home: 'Revenir au login'
    },
    notify: {
        success: 'Informations enregistrées',
        error: 'Une erreur est survenue',
        info: 'Ceci est un message d\'information'
    },
    validation: {
        mandatory: 'Ce champ est obligatoire',
        notEmpty: 'Ce champ ne peut-être vide',
        notModified: 'Aucun champ n\'a été modifié ou les éléments du mot de passe sont incorrects',
        notValidEmail: 'Cet email n\'est pas valide'
    },
    typeMismatch: 'Valeur incorrecte',
    NotEmpty: 'Ce champ est obligatoire',
    NotNull: 'Ce champ est obligatoire',
    form: {
        needInformation: 'Les champs (*) sont obligatoires',
        lastname: 'Nom *',
        firstname: 'Prénom *',
        mail: 'Adresse e-mail *',
        actualPassword: 'Saisir le mot de passe actuel',
        password: 'Saisir le nouveau mot de passe',
        passwordRepeated: 'Confirmer le nouveau mot de passe',
        error: {
            firstname: 'Aucun prénom n\'a été saisi',
            lastname: 'Aucun nom n\'a été saisi',
            email: 'Aucune adresse mail n\'a été saisie',
            password: 'Veuillez renseigner votre ancien mot de passe',
            validNewPassword: 'La saisie ne correspond pas au nouveau mot de passe'
        }
    },
    snackMessage: {
        saveOk: 'Vos informations ont bien été enregistrées'
    },
    title: {
        personalInformation: 'Informations personnelles',
        newPassword: 'Changer le mot de passe'
    }
}

export default flatten<typeof fr, ModelTranslations>(fr)
