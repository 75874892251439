import React, { useContext } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import ErrorIcon from '@material-ui/icons/Error'
import { colors } from '../../../../utils/constants'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import moment from 'moment'
import { usePhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import classnames from 'classnames'
import { ExitOfflineActionData, OfflineAction, OfflineActionData, ScrappingOfflineActionData, StockOfflineActionData } from '../../../common/offline/offlineModel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import { OfflineContext } from '../../../common/offline/context/OfflineContext'
import { getOfflinePromise } from '../../../common/offline/offlineUtils'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: 8,
			marginBottom: 12
		},
		firstRow: {
			marginBottom: 8
		},
		icon: {
			marginLeft: 4,
			height: '0.75em',
			width: '0.75em'
		},
		successIcon: {
			color: colors.secondary
		},
		loadingIcon: {
			color: colors.displayText
		},
		errorIcon: {
			color: colors.snackError
		},
		waitingIcon: {
			color: colors.displayText
		}
	})
)

type OfflineActionCardProps = {
	action: OfflineAction<OfflineActionData>
}

const OfflineActionCard = ({ action }: OfflineActionCardProps) => {
	const classes = useStyles()
	const intl = useIntl()
	const { updateActionStatus } = useContext(OfflineContext)
	const { user: { selectedLang, isDateFormatEn } } = usePhidemDataContext()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const renderStatus = () => {
		switch (action.status) {
			case 'SUCCESS':
				return <CheckIcon className={classnames(classes.icon, classes.successIcon)} />
			case 'ERROR':
				return <>
					<IconButton
						aria-label="reload"
						aria-haspopup="true"
						onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
						disableFocusRipple
						disableRipple
						size="small"
					>
						<ErrorIcon className={classnames(classes.icon, classes.errorIcon)} />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClick={() => setAnchorEl(null)}
						onClose={() => setAnchorEl(null)}
					>
						<MenuItem
							key="reload"
							onClick={() => {
								getOfflinePromise(action.type)(action.values)
									.then(() => updateActionStatus(action.uid, 'SUCCESS'))
									.catch(() => updateActionStatus(action.uid, 'ERROR'))
							}}
						>
							<FormattedMessage id="button.send" />
						</MenuItem>

					</Menu>
				</>
			case 'WAITING':
				return <HourglassEmptyIcon className={classnames(classes.icon, classes.waitingIcon)} />
		}
	}

	const renderStockInformation = () => {
		if (action.type === 'ENTRY') {
			return <></>
		}
		const actionStock = action.data as StockOfflineActionData

		return <Grid item container xs={12} justify="space-between">
			<Grid item container xs={6}>
				<Typography variant="body2">
					{actionStock.product}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2">
					{actionStock.quantity}
					<FormattedMessage
						id="global.functionnal.conditioningType"
						defaultMessage={actionStock.conditionning}
						description="Conditioning type"
						values={
							{
								count: actionStock.quantity,
								conditioningType: actionStock.conditionning
							}
						}
					/>
				</Typography>
			</Grid>
		</Grid>
	}

	const renderExitExtraInformation = () => {
		const exitAction = action.data as ExitOfflineActionData
		return <>
			{!!exitAction.seringue && <Grid item container xs={12} justify="space-between">
				<Grid item xs={6}>
					<Typography variant="body2">
						<FormattedMessage
							id="enum.exitMode.SERINGUE"
							defaultMessage="Exit mode"
							description="Exit mode label"
						/>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						{exitAction.seringue}
					</Typography>
				</Grid>
			</Grid>}
			{exitAction.exitType === 'VACCINATION_POINT' && <Grid item container xs={12} justify="space-between">
				<Grid item xs={6}>
					<Typography variant="body2">
						<FormattedMessage
							id="vaccination.synchronisation.waitingCard.exitType"
							defaultMessage="Exit type"
							description="Exit type label"
							values={{ exitType: exitAction.destination }}
						/>
					</Typography>
				</Grid>
			</Grid>}
		</>
	}

	return <Grid item xs={12}>
		<Paper className={classes.container}>
			<Grid container item xs={12} justify="space-between" alignItems="center" className={classes.firstRow}>
				<Grid item xs={7}>
					<Typography variant="h4">
						<FormattedMessage
							id={`enum.waitingAction.${action.type}`}
							defaultMessage={action.type}
							description="Offline action type"
						/>
						{action.type === 'EXIT' &&
							` / ${intl.formatMessage(
								{ id: `vaccination.exitFridge.sortieType.${(action.data as ExitOfflineActionData).exitType}` }
							)}`}
					</Typography>
				</Grid>
				<Grid item container xs={5} justify="flex-end" alignItems="center">
					<Grid item>
						<Typography variant="body2">
							{displayDate(moment(moment(action.datetime).locale(selectedLang)), DATE_FORMAT.DATETIME, isDateFormatEn)}
						</Typography>
					</Grid>
					<Grid item>{renderStatus()}</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">
					<FormattedMessage
						id="vaccination.synchronisation.waitingCard.bl"
						defaultMessage="BL"
						description="BL label"
						values={{ bl: action.data.bl }}
					/>
				</Typography>
			</Grid>
			{renderStockInformation()}
			{action.type === 'EXIT' && renderExitExtraInformation()}
			{action.type === 'SCRAPPING' && <Grid item xs={12}>
				<Typography variant="body2">
					<FormattedMessage
						id="vaccination.synchronisation.waitingCard.comment"
						defaultMessage="Comment"
						description="Comment label"
						values={{ comment: (action.data as ScrappingOfflineActionData).comment }}
					/>
				</Typography>
			</Grid>}
		</Paper>
	</Grid>
}

export default OfflineActionCard
