import React, { FC, useEffect, useState } from 'react'
import { Button, Snackbar, Typography } from '@material-ui/core'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'
import { getOS, isOnBrower } from '../../utils/platformUtils'
import { FormattedMessage } from 'react-intl'
import Dialog from '../../components/layout/dialog/Dialog'

interface BeforeInstallPromptEvent extends Event {
	/**
	 * Returns an array of DOMString items containing the platforms on which the event was dispatched.
	 * This is provided for user agents that want to present a choice of versions to the user such as,
	 * for example, "web" or "play" which would allow the user to chose between a web version or
	 * an Android version.
	 */
	readonly platforms: Array<string>

	/**
	 * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
	 */
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed',
		platform: string
	}>

	/**
	 * Allows a developer to show the install prompt at a time of their own choosing.
	 * This method returns a Promise.
	 */
	prompt(): Promise<void>

	/**
	 * Allows a developer to prevent the install prompt at a time of their own choosing.
	 * This method returns a Promise.
	 */
	preventDefault(): void
}

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent
	}
}

// Pour eviter d'avoir la popup d'install en boucle
let showInstallOnce = false
const ServiceWorkerWrapper: FC = ({ children }) => {
	const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)
	const [showInstall, setShowInstall] = useState(false)
	const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | undefined>(undefined)
	const [workerRegistration, setWorkerRegistration] = React.useState<ServiceWorkerRegistration | null>(null)

	const onUpdate = (registration: ServiceWorkerRegistration) => {
		setIsUpdateAvailable(true)
		setWorkerRegistration(registration)
	}

	useEffect(() => {
		/** Installation du service worker **/
		serviceWorkerRegistration.register({
			onSuccess: () => {
			},
			onUpdate: onUpdate
		})
	}, [])

	useEffect(() => {
		/** Gestion de la bannière d'installation **/
		const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
			if (showInstallOnce) return
			e.preventDefault()
			setDeferredPrompt(e)
			setShowInstall(true)
		}

		// ne fonctionne que sur Chrome & Windows
		if (getOS() === 'Android' && isOnBrower()) {
			/** Affichage de la demande d'installation **/
			window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
		}

		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
		}
	}, [])

	/**
	 * Reload/Update l'app pour installer le nouveau service worker
	 */
	const updateServiceWorker = () => {
		const registrationWaiting = workerRegistration && workerRegistration.waiting
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
			registrationWaiting.addEventListener('statechange', e => {
				//@ts-ignore
				if (e && e.target && e.target.state === 'activated') {
					window.location.reload()
					setIsUpdateAvailable(false)
				}
			})
		}
	}

	/**
	 * Affiche la popup d'installation de l'app
	 */
	const installAppOnHome = () => {
		// Hide the app provided install promotion
		setShowInstall(false)
		showInstallOnce = true
		// Show the install prompt
		if (deferredPrompt !== undefined) {
			deferredPrompt.prompt()
			setDeferredPrompt(undefined)
		}
	}

	return (
		<>
			<Snackbar
				open={showInstall}
				message={<FormattedMessage id="serviceWorker.addApp.title" defaultMessage="Ajouter l'application Promedeo sur l'écran d'accueil" description="Add app title" />}
				onClick={installAppOnHome}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				action={
					<Button
						color="inherit"
						size="small"
						onClick={installAppOnHome}
					>
						<FormattedMessage id="serviceWorker.addApp.btn" defaultMessage="Ajouter" description="Add app" />
					</Button>
				}
			/>
			{isUpdateAvailable && <Dialog
				title={
					<FormattedMessage
						id="serviceWorker.update.title"
						defaultMessage="Une nouvelle version disponible"
						description="Page title"
					/>
				}
				isOpen
				buttons={[
					<Button color="primary" key={0} variant="contained" onClick={updateServiceWorker}>
						<FormattedMessage
							id="serviceWorker.update.btn"
							defaultMessage="Mettre à jour"
							description="Button label"
						/>
					</Button>
				]}
			>
				<Typography variant="body1" component="p" style={{ textAlign: 'justify' }}>
					<FormattedMessage id="serviceWorker.update.description" defaultMessage="Une nouvelle version disponible, vous devez mettre à jour votre version pour continuer d'utiliser l'application." description="Page description" />
				</Typography>
			</Dialog>}
			{children}
		</>
	)
}

export default ServiceWorkerWrapper
