import { EntryOfflineActionData, ExitOfflineActionData, OfflineAction, OfflineActionType, ScrappingOfflineActionData, StockOfflineActionData } from './offlineModel'
import { RequestEntryFormModel, RequestExitFridgeFormModel, RequestScrappingFormModel, VaccinationStockModel } from '../../vaccination/services/vaccinationModels'
import moment from 'moment'
import { CentreModel, ValueListModel } from '../../common/phidemData/phidemDataModel'
import { SCRAPPING_OTHER_OPTION } from '../../../utils/constants'
import { RequestUpdateStockModel } from '../../admin/stock/services/stockModel'
import { postEntryStock, postExitFridge, postScrapping } from '../../vaccination/services/vaccinationApi'
import { updateStock } from '../../admin/stock/services/stockApi'

export const formatEntryAction = (formValues: RequestEntryFormModel): OfflineAction<EntryOfflineActionData> => {
	return {
		uid: `id-${moment().toISOString()}`,
		type: 'ENTRY',
		datetime: moment().toISOString(),
		data: { bl: formValues.numeroBl },
		status: 'WAITING',
		values: formValues
	}
}

export const formatExitAction = (selectedStock: VaccinationStockModel,
                                 children: CentreModel[],
                                 formValues: RequestExitFridgeFormModel,
                                 promise: any): OfflineAction<ExitOfflineActionData> => {
	return {
		uid: `id-${moment().toISOString()}`,
		type: 'EXIT',
		datetime: moment().toISOString(),
		data: {
			bl: selectedStock.ligneBl.numeroBl,
			product: selectedStock.ligneBl.produit.nomCommercial,
			quantity: formValues.quantite,
			conditionning: selectedStock.ligneBl.produit.typeConditionnement,
			seringue: formValues.seringue,
			conditionningType: formValues.typeConditionnement,
			exitType: formValues.sortieType,
			destination: formValues.idPoint && children.find(v => v.id === formValues.idPoint)?.nom
		},
		status: 'WAITING',
		values: formValues
	}
}

export const formatScrappingAction = (selectedStock: VaccinationStockModel,
                                      scrappingCauseOptions: ValueListModel,
                                      formValues: RequestScrappingFormModel,
                                      otherCauseLabel: string): OfflineAction<ScrappingOfflineActionData> => {
	const comment = formValues.causeDechet === SCRAPPING_OTHER_OPTION ?
		`${otherCauseLabel} : ${formValues.commentaireDechet}`
		: scrappingCauseOptions[formValues.causeDechet]

	return {
		uid: `id-${moment().toISOString()}`,
		type: 'SCRAPPING',
		datetime: moment().toISOString(),
		data: {
			bl: selectedStock.ligneBl.numeroBl,
			product: selectedStock.ligneBl.produit.nomCommercial,
			quantity: formValues.quantite,
			conditionning: selectedStock.ligneBl.produit.typeConditionnement,
			comment
		},
		status: 'WAITING',
		values: formValues
	}
}

export const formatUpdateStockAction = (selectedStock: VaccinationStockModel,
                                 formValues: RequestUpdateStockModel): OfflineAction<StockOfflineActionData> => {
	return {
		uid: `id-${moment().toISOString()}`,
		type: 'UPDATE_STOCK',
		datetime: moment().toISOString(),
		data: {
			bl: selectedStock.ligneBl.numeroBl,
			product: selectedStock.ligneBl.produit.nomCommercial,
			quantity: formValues.quantite ?? 0,
			conditionning: selectedStock.ligneBl.produit.typeConditionnement,
		},
		status: 'WAITING',
		values: formValues
	}
}

export const getOfflinePromise = (actionType: OfflineActionType): any => {
	switch (actionType) {
		case 'ENTRY':
			return postEntryStock
		case 'EXIT':
			return postExitFridge
		case 'SCRAPPING':
			return postScrapping
		case 'UPDATE_STOCK':
			return updateStock
		default:
			return Promise.resolve()
	}
}
